import { Checkbox } from '../../../../../../../components/common/form-elements/Checkbox';
import { SelectStorageEntryType } from '../SelectStorageEntryType';
import { WineStorageEntryType } from '../../../types/WineStorage';
import SelectTank from '../../../../wine/components/WineForm/SelectTank';
import { SelectType } from '../../../../../../../components/common/form-elements/SelectType';
import { useWineProductionsReceiver } from '../../../../wine_production/hooks/useWineProductionsReceiver';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../../layouts/PageWrapper';
import { Tank } from '../../../../tank/types/Tank';
import { WineProduction } from '../../../../wine/types/WineProduction';
import { ServiceError } from '../../../../../../../types/Service';
import InputElement from '../../../../../../../components/common/InputElement';
import { useResponsiveContext } from '../../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../../hooks/useToggleAndGoToRef';

interface Props {
  hasWineProduction: boolean;
  updateWineProductionCheckbox: () => void;
  selectDifferentTank: boolean;
  updateTankCheckbox: () => void;
  entryType: WineStorageEntryType;
  updateEntryType: () => void;
  tank: Tank;
  updateTank: () => void;
  updateQuantity: () => void;
  liters: number;
  updateLiters: () => void;
  wineProduction: WineProduction;
  updateWineProduction: () => void;
  onSubmit: () => void;
  error: ServiceError;
  loading: boolean;
  disableTankAndLiters: boolean;
}

export const AddStorageEntryFormPresentation: FC<Props> = ({
  hasWineProduction,
  updateWineProductionCheckbox,
  selectDifferentTank,
  updateTankCheckbox,
  entryType,
  updateEntryType,
  tank,
  updateTank,
  updateQuantity,
  liters,
  updateLiters,
  wineProduction,
  updateWineProduction,
  onSubmit,
  error,
  loading,
  disableTankAndLiters
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);

  return (
    <PageWrapper disabled loading={loading}>
      <Card innerRef={currentRef} className={isOpen ? '' : 'bg-info-dark'}>
        <CardBody className={'pt-2 pb-2'}>
          <div
            onClick={() => toggleOpen()}
            style={{ cursor: 'pointer', height: '1.5em' }}
            className={'pt-1'}
          >
            <i
              className="float-left"
              data-tool="card-collapse"
              data-toggle="tooltip"
              title=""
              data-original-title="Collapse Card"
            >
              <em className={isOpen ? 'fa fa-minus text-primary' : 'fa fa-plus'} />
            </i>
            <h5 className="m-0 float-right">{t('wineStorage.addEntry')}</h5>
          </div>
          {isOpen && (
            <div className={'mt-2'}>
              <div className="row pl-3 pb-2 pt-1">
                <Checkbox
                  name={'hasWineProduction'}
                  label={t('wineStorage.entry.checkbox.WINE_ENTRY')}
                  value={hasWineProduction}
                  onChange={updateWineProductionCheckbox}
                />
                {entryType === WineStorageEntryType.TANK && wineProduction?.tank && (
                  <Checkbox
                    name={'hasWineProduction'}
                    label={t('wineStorage.entry.checkbox.TANK')}
                    value={selectDifferentTank}
                    onChange={updateTankCheckbox}
                  />
                )}
              </div>
              <div>
                <SelectStorageEntryType
                  name={'entryType'}
                  value={entryType}
                  onChange={updateEntryType}
                  optional
                  label={t('wineStorage.entry.TYPE')}
                  key={entryType}
                />
                {hasWineProduction && (
                  <SelectType
                    onChange={updateWineProduction}
                    name={'wineProduction'}
                    receiver={useWineProductionsReceiver}
                    error={error}
                    label={t('wineStorage.entry.WINE_PRODUCTION')}
                    optional
                    value={wineProduction}
                    errorName={'wineProduction'}
                  />
                )}
                {entryType === WineStorageEntryType.TANK && (
                  <SelectTank
                    value={tank}
                    name={'tank'}
                    onChange={updateTank}
                    label={t('wineStorage.entry.TANK')}
                    error={error}
                    key={(wineProduction?.id ? 2 : 3) + (selectDifferentTank ? 1 : 0)}
                    disabled={disableTankAndLiters}
                  />
                )}
                {entryType === WineStorageEntryType.BOTTLE && (
                  <InputElement
                    name={'quantity'}
                    onChange={updateQuantity}
                    label={t('wineStorage.entry.QUANTITY')}
                    placeholder={
                      wineProduction?.liters
                        ? 'max ' + Math.floor(wineProduction?.liters / 0.75)
                        : ''
                    }
                    type={'number'}
                    className={'mb-0 pb-2'}
                    error={error}
                  />
                )}
                {entryType === WineStorageEntryType.TANK && (
                  <InputElement
                    name={'liters'}
                    onChange={updateLiters}
                    defaultValue={liters}
                    placeholder={
                      wineProduction?.liters ? 'max ' + wineProduction?.liters + ' l.' : ''
                    }
                    type={'number'}
                    label={t('wineStorage.entry.LITERS')}
                    error={error}
                    className={'mb-0 pb-2'}
                    disabled={disableTankAndLiters}
                  />
                )}
              </div>
              <FormErrorMessage error={error} messageType={'details'} clazzName={'p-1'} />
              <div className={'text-center bg-secondary'}>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={onSubmit}
                  disabled={!entryType}
                >
                  {' '}
                  {t('common.ADD')}
                </button>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </PageWrapper>
  );
};
