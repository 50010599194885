import React from 'react';
import ReactDOM from 'react-dom';
import './assets/Popup.css';
import { usePopupContext } from './context/PopupContext';
import { useResponsiveContext } from '../platform/ResponsiveContext';

const Popup = () => {
  const { showPopup, component, buttons } = usePopupContext();
  const { isMobile } = useResponsiveContext();

  const portal = document.getElementById('portal');
  if (!portal) {
    throw new Error('Could not find portal element');
  }
  if (!showPopup) return null;
  else
    return ReactDOM.createPortal(
      <div className="popup-container" style={{ alignItems: isMobile ? '' : 'center' }}>
        <div className={'card bg-light ' + (isMobile ? 'popup-mobile' : 'popup')}>
          {component}
          <div className={'card-header text-center'}>{buttons}</div>
        </div>
      </div>,
      portal
    );
};

export default Popup;
