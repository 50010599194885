import React from 'react';
import { Plans } from '../../../plans/data/Plans';
import { SubscriptionType } from '../../../../types/Subscription';
import { PlanItem } from '../../../plans/components/PlanItem';

export const CurrentPlanInfo: React.FC<{ subscriptionType: SubscriptionType }> = ({
  subscriptionType
}) => {
  return (
    <div>
      <h4 className={'py-3'}>Twoje aktywne opcje:</h4>
      <div
        className={'text-left'}
        style={{ marginLeft: 'auto', marginRight: 'auto', width: '190px' }}
      >
        {Plans.find((plan) => plan.type === subscriptionType).items.map((item) => {
          return <PlanItem title={item.title} include={item.include} key={item.title} />;
        })}
      </div>
    </div>
  );
};
