import React from 'react';
import { Subscription, SubscriptionStatus, SubscriptionType } from '../../../../types/Subscription';
import { PaymentInfo } from './PaymentInfo';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../platform/AuthContext';
import { Plans } from '../../../plans/data/Plans';

export const TrialPlanInfo: React.FC<{
  navigateToPlans: () => void;
  subscription: Subscription;
  inProgressSubscription?: Subscription;
  color: string;
}> = ({ navigateToPlans, subscription, inProgressSubscription, color }) => {
  const { t } = useTranslation();
  const { principal } = useAuthContext();
  const chosenPlan = Plans.find(
    (plan) => plan.type === inProgressSubscription?.type && plan.status !== SubscriptionStatus.TRIAL
  );
  const currentPlan = Plans.find(
    (plan) => plan.type === subscription?.type && plan.status !== SubscriptionStatus.TRIAL
  );
  return (
    <div>
      {inProgressSubscription ? (
        <div className={'pb-2'}>
          Po zaksięgowaniu wpłaty twój nowy plan{' '}
          <span className={color}>
            {t(`plans.subscription.type.${inProgressSubscription.type}`)}
          </span>{' '}
          zostanie aktywowany.
        </div>
      ) : null}
      <div className={'pb-3'}>
        Okres próbny kończy się: <span className={'text-warning'}>{subscription.validToDate}</span>
      </div>
      {subscription.type === SubscriptionType.PREMIUM ? (
        <div>
          <div>Aby zachować wszystkie funkcje, opłać plan zgodnie z danymi poniżej.</div>
          Możesz jeszcze{' '}
          <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
            zmienić plan.
          </span>
        </div>
      ) : (
        <div>
          <div>Aby uzyskać dostęp do większej ilości funkcji w każdej chwili możesz</div>
          <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
            zwiększyć plan.
          </span>
        </div>
      )}

      <div className={'pb-3'}>
        <PaymentInfo
          price={chosenPlan?.amount?.toString() || currentPlan?.amount?.toString()}
          title={principal.login + ' - ' + (inProgressSubscription?.type || subscription.type)}
        />
      </div>
    </div>
  );
};
