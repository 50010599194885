import { Ingredient } from '../../ingredient/types/Ingredient';
import { Harvest } from '../../harvest/types/Harvest';
import { Tank } from '../../tank/types/Tank';
import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';
import { History } from './History';
import { WineEntry } from './WineProduction';

export enum WineStatus {
  SEDIMENTATION,
  MACERATION,
  FERMENTATION,
  DEACIDIFICATION,
  CLARIFICATION,
  AGING
}

export enum ProductionEventForWineProductionType {
  SEDIMENTATION = 'SEDIMENTATION',
  MACERATION = 'MACERATION',
  FERMENTATION = 'FERMENTATION',
  MALOLACTIC_FERMENTATION = 'MALOLACTIC_FERMENTATION',
  DEACIDIFICATION = 'DEACIDIFICATION',
  CLARIFICATION = 'CLARIFICATION',
  SUGARING = 'CLARIFICATION',
  ALCOHOL_INCREASING = 'ALCOHOL_INCREASING',
  DECANTING_ABOVE_SEDIMENT = 'DECANTING_ABOVE_SEDIMENT',
  FILTERING = 'FILTERING',
  BOTTLING = 'BOTTLING',
  OTHER = 'OTHER'
}

export enum ProductionEventForWineType {
  SEDIMENTATION = 'SEDIMENTATION',
  MACERATION = 'MACERATION',
  PRESS = 'PRESS',
  DEACIDIFICATION = 'DEACIDIFICATION',
  CLARIFICATION = 'CLARIFICATION',
  SUGARING = 'CLARIFICATION',
  ALCOHOL_INCREASING = 'ALCOHOL_INCREASING',
  DECANTING_ABOVE_SEDIMENT = 'DECANTING_ABOVE_SEDIMENT',
  FILTERING = 'FILTERING',
  BOTTLING = 'BOTTLING',
  OTHER = 'OTHER'
}

export interface ProductionEvent {
  id?: number | null;
  name?: string;
  type: ProductionEventForWineType | null;
  info?: string;
  startingDate: Date | string | null;
  endingDate?: Date | string | null;
  waste?: number | null;
}

export interface Wine {
  id?: number;
  name: string;
  tank: Tank;
  startDate?: string;
  liters: number;
  startLiters: number;
  wasteLiters: number;
  disposedLiters: number;
  pressWasteLiters: number;
  endLiters: number;
  harvest: Harvest | null;
  ingredients: Ingredient[];
  history: History[];
  status: WineStatus;
  events: ProductionEvent[];
  lastIngredient: Ingredient;
  lastEvent: ProductionEvent;
  liveStatus?: EntityLiveStatus;
  disposedAsWineEntry: WineEntry[];
}

export interface WinePresentation {
  id: number;
  name: string;
  tankNumber: string;
  tankCapacity: number;
  startDate?: string;
  liters: number;
  liveStatus: EntityLiveStatus;
}
