import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { useWineProductionContext } from '../../context/WineProductionContext';
import useWineProductionService from '../../hooks/useWineProductionService';
import { Button, WineInfoButtons } from '../../../wine/components/WineInfo/WineInfoContainer';
import { useTranslation } from 'react-i18next';
import { Tank } from '../../../tank/types/Tank';
import { RouterParams } from '../../../../../../types/RouterParams';

const WineProductionInfoContainer = ({ render }) => {
  const { updateWineProduction, setWineProductionResult, wineProduction, error, setError } =
    useWineProductionContext();
  const service = useWineProductionService();
  const { wineProductionId } = useParams<RouterParams>();
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();

  useEffect(() => {
    setWineProductionResult({ status: StatusType.loading });
    wineProductionId &&
      service
        .get(parseInt(wineProductionId))
        .then((response) => {
          setWineProductionResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setWineProductionResult(new ResponseError(error)));

    return () => updateWineProduction('reset', '');
  }, [wineProductionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const editWine = () => {
    pushHistory(`/mv/wine_production/e/${wineProductionId}/`);
  };

  const archive = () => {
    const action =
      wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED
        ? service.revertArchive
        : service.archive;
    action(wineProduction?.id)
      .then((response) => {
        setWineProductionResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineProductionResult({ status: StatusType.error });
      });
  };

  const updateTankInWineProduction = (selected: { value: number }) => {
    const tank: Tank = { id: selected.value };
    setWineProductionResult({ status: StatusType.loading });
    service
      .placeInTank(tank)
      .then((response) => {
        setWineProductionResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineProductionResult({ status: StatusType.error });
      });
  };

  const saveEditButton = {
    action: editWine,
    label: wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED ? 'Podgląd' : 'Edytuj'
  };

  const archiveButton: Button = {
    action: archive,
    label:
      wineProduction?.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE')
  };

  const buttons: WineInfoButtons = {
    saveEditButton,
    archiveButton
  };

  return render(buttons, updateTankInWineProduction, error);
};

export default WineProductionInfoContainer;
