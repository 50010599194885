import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileItemType } from '../../../types/FileType';

export const FileItem: React.FC<FileItemType> = ({ title, type, description, url }) => {
  const { t } = useTranslation();
  function getIcon() {
    if (type === 'vineyard') return 'fa-file-image text-green';
    if (type === 'winery') return 'fa-file-archive text-info';
    if (type === 'storage') return 'fa-file-archive text-warning';
    if (type === 'kowr') return 'fa-file-code text-purple';
    return 'fa-file-archive text-primary';
  }

  function getPdf() {
    window.location.href = `${url}`;
  }

  return (
    <div className="col-lg-4 col-md-6">
      <div className="card" style={{ height: '90%' }}>
        <div className="card-body text-center">
          <div className="d-flex">
            <div className="ml-auto">
              <small className="mr-2 text-muted">{t(`files.type.${type}`)}</small>
              <span
                className="text-muted mr-1"
                title="Pobierz"
                onClick={getPdf}
                style={{ cursor: 'pointer' }}
              >
                <em className="fa fa-download fa-fw" />
              </span>
            </div>
          </div>
          <span className="px-4" onClick={getPdf} style={{ cursor: 'pointer' }}>
            <em className={`fa-5x far ${getIcon()}`} />
          </span>
          <p>
            <small className="text-dark">{title}</small>
          </p>
          <div className="d-flex m-0 text-muted">
            <small className="ml-auto">{description}</small>
          </div>
        </div>
      </div>
    </div>
  );
};
