import React from 'react';
import { SubscriptionStatus, SubscriptionType } from '../../../types/Subscription';
import { useTranslation } from 'react-i18next';

export type PlanSectionType = {
  title: string;
  type: SubscriptionType;
  status?: SubscriptionStatus;
  amount: number;
  buttonLabel: string;
  color: 'base' | 'standard' | 'premium';
};

export const PlanSection: React.FC<
  PlanSectionType & {
    children: React.ReactNode;
    onSelect: (type: SubscriptionType) => void;
    currentPlan: SubscriptionType;
    planInProgress: SubscriptionType;
    loading: boolean;
  }
> = ({
  children,
  title,
  type,
  status,
  amount,
  buttonLabel,
  color,
  onSelect,
  currentPlan,
  planInProgress,
  loading
}) => {
  const { t } = useTranslation();

  function getColors() {
    const isButtonDisabled = buttonDisabled();
    if (color === 'base') return ['bg-secondary', 'bg-secondary'];
    if (color === 'standard') return ['bg-green', isButtonDisabled ? 'bg-secondary' : 'bg-danger'];
    return ['', isButtonDisabled ? 'bg-secondary' : ''];
  }

  function buttonDisabled() {
    if (status === SubscriptionStatus.PAST_DUE) {
      return false;
    }
    if (status === SubscriptionStatus.ACTIVATED && currentPlan === type) {
      return false;
    }
    return (
      loading ||
      (currentPlan === type && planInProgress === undefined) ||
      (SubscriptionStatus.ACTIVATED === status &&
        (currentPlan === type ||
          planInProgress === type ||
          (currentPlan &&
            currentPlan === SubscriptionType.NORMAL &&
            type === SubscriptionType.BASIC) ||
          (currentPlan &&
            currentPlan === SubscriptionType.PREMIUM &&
            (type === SubscriptionType.BASIC || type === SubscriptionType.NORMAL))))
    );
  }

  function getLabel() {
    if (currentPlan === type) return t('plans.button.label.active');
    if (planInProgress === type) return t('plans.button.label.inProgress');
    if (buttonDisabled()) return t('plans.button.label.cannotChoose');
    return buttonLabel;
  }

  const style = { borderTopLeftRadius: '10px', borderTopRightRadius: '10px' };

  const amountSplit = amount.toString().split('.');
  return (
    <div className="card" style={{ minWidth: '230px' }}>
      <div
        className={`card-body text-center bb ${getColors()?.[0]}`}
        style={color === 'premium' ? { ...style, backgroundColor: '#ffd700' } : style}
      >
        <div className="text-bold">{title}</div>
        <h3 className="my-3">
          {amount > 0 ? (
            <>
              <sup>PLN </sup>
              <span className="text-lg">{amountSplit[0]}</span>
              <sup>
                <sup>{amountSplit[1] && amountSplit[1] !== '0' ? amountSplit[1] : ''}</sup>
              </sup>
              <span>/mies.</span>{' '}
            </>
          ) : (
            <span className="text-lg">Bezpłatnie</span>
          )}
        </h3>
      </div>
      <div className="card-body">{children}</div>
      <div className="card-body text-center">
        <button
          className={`btn btn-secondary btn-lg ${getColors()?.[1]}`}
          style={
            color === 'premium' && !buttonDisabled()
              ? { backgroundColor: '#656565', color: '#ffd700' }
              : {}
          }
          onClick={() => onSelect(type)}
          disabled={buttonDisabled()}
        >
          {getLabel()}
        </button>
      </div>
    </div>
  );
};
