import React from 'react';
import { BoxWithGrapes } from '../../HarvestInfo/box/types/BoxWithGrapes';
import { Button } from '../../../../../../../components/common/buttons/Button';

interface IProps {
  box: BoxWithGrapes;
  disposed: boolean;
  remove: (box: BoxWithGrapes) => void;
  reloadHarvest: () => void;
}

export const BoxInHarvestRow: React.FC<IProps> = ({ box, disposed, remove, reloadHarvest }) => {
  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }}>
      <td>{Math.round((box?.weightOfFullBox - box?.weightOfEmptyBox) * 100) / 100}</td>
      <td>{box.weightOfEmptyBox || 0}</td>
      <td>{box.weightOfFullBox || 0}</td>
      <td style={{ padding: '5px', textAlign: 'center' }} key={5}>
        {!disposed ? (
          <Button.Delete
            onClick={() => {
              remove(box);
              reloadHarvest();
            }}
          />
        ) : (
          <div className="card-body d-flex align-items-center p-1">
            <em className="fa-2x mr-2 fas fa-ban" />
          </div>
        )}
      </td>
    </tr>
  );
};
