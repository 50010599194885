import React, { useRef, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { CompanyInfoData } from '../../types';
import { useTranslation } from 'react-i18next';
import SelectSubscriptionType from './SelectSubscriptionType';
import useSubscriptionService from '../../../plans/hooks/useSubscriptionService';
import { SelectOption } from '../../../../types/SelectOption';
import { ServiceError } from '../../../../types/Service';
import { ResponseError } from '../../../error/ResponseError';
import SelectSubscriptionStatus from './SelectSubscriptionStatus';
import { SubscriptionPeriod, SubscriptionStatus } from '../../../../types/Subscription';
import { useConfirmation } from '../../../notifications/useConfirmation';
import { FormErrorMessage } from '../../../../components/notifications/FormErrorMessage';

interface IProps {
  company: CompanyInfoData;
  reload: (value: boolean) => void;
}

export const CompanySubscription: React.FC<IProps> = ({ company, reload }) => {
  const { t } = useTranslation();
  const serviceRef = useRef(useSubscriptionService());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ServiceError>();

  const showConfirmation = useConfirmation();

  function handleUpdateSubscriptionType(name: string, selected: SelectOption) {
    setLoading(true);
    serviceRef.current
      .selectSubscription({ ...company, subscriptionType: selected.value })
      .then(() => {
        reload(true);
      })
      .catch((err) => {
        console.error(err);
        setError(new ResponseError(err));
      })
      .finally(() => setLoading(false));
  }

  function handleUpdateSubscriptionStatus(name: string, selected: SelectOption) {
    setLoading(true);
    serviceRef.current
      .changeStatus({ ...company, subscriptionStatus: selected.value })
      .then(() => {
        reload(true);
      })
      .catch((err) => {
        console.error(err);
        setError(new ResponseError(err));
      })
      .finally(() => setLoading(false));
  }

  const handleChangeSubscriptionValidTo = ({
    period,
    increase
  }: {
    period: SubscriptionPeriod;
    increase: boolean;
  }) => {
    showConfirmation({
      message: t('alerts.confirmation.changeValidToDate'),
      actionLabel: 'Zmień',
      actionButtonColor: increase ? 'success' : 'danger',
      actionCallback: () =>
        changeSubscriptionValidTo({
          period,
          increase
        })
    });
  };

  function changeSubscriptionValidTo({
    period,
    increase
  }: {
    period: SubscriptionPeriod;
    increase: boolean;
  }) {
    setLoading(true);
    serviceRef.current
      .increase(company.id, { period, increase })
      .then(() => {
        reload(true);
      })
      .catch((err) => {
        console.error(err);
        setError(new ResponseError(err));
      })
      .finally(() => setLoading(false));
  }

  const handleConfirmSubscription = () => {
    showConfirmation({
      message: t('alerts.confirmation.confirmSubscription'),
      actionLabel: 'Tak',
      actionButtonColor: 'success',
      actionCallback: confirmSubscription
    });
  };

  function confirmSubscription() {
    setLoading(true);
    serviceRef.current
      .confirm(company)
      .then(() => {
        reload(true);
      })
      .catch((err) => {
        console.error(err);
        setError(new ResponseError(err));
      })
      .finally(() => setLoading(false));
  }

  const handleRejectSubscription = () => {
    showConfirmation({
      message: t('alerts.confirmation.rejectSubscription'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: rejectSubscription
    });
  };

  function rejectSubscription() {
    setLoading(true);
    serviceRef.current
      .reject(company)
      .then(() => {
        reload(true);
      })
      .catch((err) => {
        console.error(err);
        setError(new ResponseError(err));
      })
      .finally(() => setLoading(false));
  }

  return (
    <Card>
      <CardBody>
        <span className="text-muted float-left">{t('company.summary.subscription')}</span>
        <Table>
          <tbody>
            <CommonRow
              label={t('company.summary.subscriptionType')}
              value={
                <SelectSubscriptionType
                  value={company.subscriptionType}
                  name={'subscriptionType'}
                  onChange={handleUpdateSubscriptionType}
                  loading={loading}
                  error={error}
                />
              }
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.summary.subscriptionStatus')}
              value={
                <SelectSubscriptionStatus
                  value={company.subscriptionStatus}
                  name={'subscriptionStatus'}
                  onChange={handleUpdateSubscriptionStatus}
                  loading={loading}
                  error={error}
                />
              }
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.confirm')}
              value={
                <div className={'row float-right'}>
                  <button
                    className={' btn btn-sm btn-danger mr-2'}
                    onClick={() => handleRejectSubscription()}
                    disabled={company.subscriptionStatus !== SubscriptionStatus.DISABLED}
                  >
                    Odrzuć
                  </button>
                  <button
                    className={' btn btn-sm btn-success'}
                    onClick={() => handleConfirmSubscription()}
                    disabled={company.subscriptionStatus !== SubscriptionStatus.DISABLED}
                  >
                    Aktywuj
                  </button>
                </div>
              }
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.subscriptionCreated')}
              value={company.subscriptionCreated?.toString()}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.subscriptionValidToDate')}
              value={company.subscriptionValidToDate?.toString()}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.subscriptionValidToDate.increase.month')}
              value={
                <div>
                  <button
                    className={' btn btn-sm btn-outline-danger mr-2'}
                    onClick={() =>
                      handleChangeSubscriptionValidTo({
                        period: SubscriptionPeriod.MONTHLY,
                        increase: false
                      })
                    }
                  >
                    -
                  </button>
                  <button
                    className={' btn btn-sm btn-info'}
                    onClick={() =>
                      handleChangeSubscriptionValidTo({
                        period: SubscriptionPeriod.MONTHLY,
                        increase: true
                      })
                    }
                  >
                    +
                  </button>
                </div>
              }
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('company.subscription.subscriptionValidToDate.increase.year')}
              value={
                <div>
                  <button
                    className={' btn btn-sm btn-outline-danger mr-2'}
                    onClick={() =>
                      handleChangeSubscriptionValidTo({
                        period: SubscriptionPeriod.YEARLY,
                        increase: false
                      })
                    }
                  >
                    -
                  </button>
                  <button
                    className={' btn btn-sm btn-info'}
                    onClick={() =>
                      handleChangeSubscriptionValidTo({
                        period: SubscriptionPeriod.YEARLY,
                        increase: true
                      })
                    }
                  >
                    +
                  </button>
                </div>
              }
              valueColumnClassName={'text-right'}
            />
          </tbody>
        </Table>
        <FormErrorMessage error={error} messageType={'details'} />
      </CardBody>
    </Card>
  );
};
