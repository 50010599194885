import React, { useCallback, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import useBoardService from '../../hooks/useBoardService';
import { StartBoardData } from '../../../../types/dto';
import { VarietiesCard } from '../VineyardSummaryPage/varieties/VarietiesCard';
import { WinesInTanksCard } from '../WinerySummaryPage/wines/WinesInTanksCard';
import { SelectedWines } from '../StorageSummaryPage/SelectedWinesCard';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import { StartBoardCardsMenu } from './StartBoardCardsMenu';
import { StorageWinesListTable } from '../StorageSummaryPage/StorageWinesListTable';
import { TaxBandsNeedAttentionCard } from '../StorageSummaryPage/TaxBandsNeedAttentionCard';
import { Col, Row } from 'reactstrap';
import { HarvestsListCard } from '../VineyardSummaryPage/harvests/HarvestsListCard';
import { WinesListCard } from '../WinerySummaryPage/wines/WinesListCard';
import { useAuthContext } from '../../../platform/AuthContext';
import { useTranslation } from 'react-i18next';

type ActiveTab = 'favorite' | 'inTanks' | 'grapevines' | 'winesNeedAttention';

export const StartPage = () => {
  const {
    actions: { hasValidPremium }
  } = useAuthContext();
  const serviceRef = useRef(useBoardService());
  const [startBoardData, setStartBoardData] = useState<StartBoardData>(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<ActiveTab>('favorite');
  const { isMobile } = useResponsiveContext();
  const { t } = useTranslation();

  const getDefaultActiveTab = useCallback(
    (startBoardData: StartBoardData): ActiveTab => {
      if (hasValidPremium && startBoardData?.favoriteWinesInStorage.length) return 'favorite';
      if (startBoardData?.winesInTanks.length) return 'inTanks';
      if (startBoardData?.grapevines.length) return 'grapevines';
      return 'winesNeedAttention';
    },
    [hasValidPremium]
  );

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getStartBoard()
        .then((response) => {
          setLoading(false);
          setStartBoardData(response);
          setActiveTab(getDefaultActiveTab(response));
        })
        .catch(() => setLoading(false));
    }
  }, [loading, setStartBoardData, setActiveTab, getDefaultActiveTab]);

  if (startBoardData === null) return <PageWrapper title={'home.START'} loading={loading} />;

  return (
    <PageWrapper title={'home.START'} loading={loading}>
      <div>{!isMobile ? <StartBoardCardsMenu small /> : null}</div>
      <table className="table-responsive w-100 no-footer text-nowrap">
        <tbody>
          <tr>
            <td>
              {startBoardData?.winesNeedAttention.length ? (
                <button
                  className={
                    'btn btn-sm mr-2 mb-2 ' +
                    (activeTab === 'winesNeedAttention' ? 'btn-info' : 'btn-outline-warning')
                  }
                  type="button"
                  onClick={() => setActiveTab('winesNeedAttention')}
                >
                  Potrzeba uwagi
                </button>
              ) : null}
            </td>
            <td>
              {hasValidPremium && startBoardData?.favoriteWinesInStorage.length > 0 ? (
                <button
                  className={
                    'btn btn-sm mr-2 mb-2 ' +
                    (activeTab === 'favorite' ? 'btn-info' : 'btn-secondary')
                  }
                  type="button"
                  onClick={() => setActiveTab('favorite')}
                >
                  Ulubione wina
                </button>
              ) : null}
            </td>
            <td>
              {startBoardData?.winesInTanks.length > 0 ? (
                <button
                  className={
                    'btn btn-sm mr-2 mb-2 ' +
                    (activeTab === 'inTanks' ? 'btn-info' : 'btn-secondary')
                  }
                  type="button"
                  onClick={() => setActiveTab('inTanks')}
                >
                  W zbiornikach
                </button>
              ) : null}
            </td>
            <td>
              {startBoardData?.grapevines.length ? (
                <button
                  className={
                    'btn btn-sm mr-2 mb-2 ' +
                    (activeTab === 'grapevines' ? 'btn-info' : 'btn-secondary')
                  }
                  type="button"
                  onClick={() => setActiveTab('grapevines')}
                >
                  Odmiany winorośli
                </button>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
      <div className={'pt-2'}>
        <div style={{ minHeight: activeTab !== 'winesNeedAttention' ? '238px' : '' }}>
          {hasValidPremium &&
          startBoardData?.favoriteWinesInStorage.length > 0 &&
          activeTab === 'favorite' ? (
            <SelectedWines wines={startBoardData?.favoriteWinesInStorage} />
          ) : null}
          {startBoardData?.winesInTanks.length > 0 && activeTab === 'inTanks' ? (
            <WinesInTanksCard wines={startBoardData.winesInTanks} />
          ) : null}
          {startBoardData?.grapevines.length > 0 && activeTab === 'grapevines' ? (
            <VarietiesCard
              grapevines={startBoardData.grapevines}
              totalPlants={startBoardData.totalPlants}
              vineyardArea={startBoardData.vineyardArea}
            />
          ) : null}
        </div>
        <Row>
          <Col
            xl={
              startBoardData?.taxBandsPlacementsNeedAttention?.length &&
              startBoardData?.winesNeedAttention.length
                ? '4'
                : '12'
            }
            lg="12"
          >
            {startBoardData?.taxBandsPlacementsNeedAttention?.length &&
            activeTab === 'winesNeedAttention' ? (
              <TaxBandsNeedAttentionCard
                title={t('storage.board.wines.table.taxBandsNeedAttention.title')}
                taxBandsPlacementsNeedAttention={startBoardData.taxBandsPlacementsNeedAttention}
              />
            ) : null}
          </Col>
          <Col
            xl={
              startBoardData?.taxBandsPlacementsNeedAttention?.length &&
              startBoardData?.winesNeedAttention.length
                ? '8'
                : '12'
            }
            lg="12"
          >
            {startBoardData?.winesNeedAttention.length && activeTab === 'winesNeedAttention' ? (
              <StorageWinesListTable wines={startBoardData.winesNeedAttention} short={isMobile} />
            ) : null}
          </Col>
        </Row>
        <div>{isMobile ? <StartBoardCardsMenu /> : null}</div>
        <Row>
          <Col>
            <HarvestsListCard harvests={startBoardData.seasonHarvest} />
          </Col>
          <Col>
            <WinesListCard wines={startBoardData.winesInSeason} />
          </Col>
        </Row>
      </div>
    </PageWrapper>
  );
};
