import React from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { useWineProductionContext } from '../../context/WineProductionContext';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import WineInfoBox, { WineInfo } from '../../../../common/WineInfoBox';
import useWineProductionService from '../../hooks/useWineProductionService';
import { ProductionEventForm } from '../../../production_event/components/ProductionEventForm';
import WineHistory from '../../../wine/components/WineInfo/history/WineHistory';
import PanelTab from '../../../../../../components/common/PanelTab/PanelTab';
import { ApplyIngredient } from '../../../ingredient/components/apply/ApplyIngredient';
import IngredientInfoPanelTab from '../../../ingredient/components/IngredientInfoPanelTab';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { WineEntriesListBox } from '../WineEntriesListBox';
import { AddWineEntryForm } from '../AddWineEntryForm';
import WineProductionIngredientsListBox from './WineProductionIngredientsListBox';
import { WineProductionEventsListBox } from './WineProductionEventsListBox';
import { DisposedWineEntriesListBox } from '../DisposedWineEntriesListBox';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

const WineProductionInfoPanel = ({ buttons, updateTankInWineProduction, error }) => {
  const { t } = useTranslation();
  const { wineProduction, loading } = useWineProductionContext();
  const service = useWineProductionService();
  const wineInfo: Partial<WineInfo> = { ...wineProduction, entity: 'wine_production' };

  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/production`);
  }

  return (
    <PageWrapper
      title={'wineProduction.TITLE'}
      subtitle={'wineProduction.SUBTITLE'}
      loading={loading}
      onClick={pushToBoard}
    >
      {wineProduction?.liveStatus !== EntityLiveStatus.ARCHIVED && wineProduction?.id ? (
        <Row>
          <Col xl="4" lg="6">
            <WineInfoBox
              buttons={buttons}
              wine={wineInfo}
              loading={loading}
              updateTankInWineProduction={updateTankInWineProduction}
              error={error}
            />
            <AddWineEntryForm />
            <WineEntriesListBox
              wineEntries={wineProduction.wineEntries || []}
              removeWineEntries={service.removeWineEntry}
            />
            {wineProduction?.disposedAsWineEntry.length ? (
              <DisposedWineEntriesListBox wineEntries={wineProduction?.disposedAsWineEntry || []} />
            ) : null}
          </Col>
          <Col xl="4" lg="6">
            <ProductionEventForm entity={'wine_production'} />
            <WineProductionEventsListBox
              events={wineProduction?.events || []}
              removeEvent={service.removeEvent}
              loading={loading}
              entityId={wineProduction?.id}
            />
          </Col>
          <Col xl="4" lg="6">
            <PanelTab
              title={t('add_ingredient.ADD_WINE_PRODUCTION')}
              tab1Label={t('add_ingredient.ADD_WINE.FORM')}
              tab1={<ApplyIngredient entity={'wine_production'} />}
              tab2Label={t('add_ingredient.INGREDIENT_INFO')}
              tab2={<IngredientInfoPanelTab />}
            />
            <WineProductionIngredientsListBox
              ingredients={wineProduction?.ingredients || []}
              delIngredient={service.delIngredient}
              loading={loading}
              entityId={wineProduction?.id}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xl="4" lg="6">
            <WineInfoBox buttons={buttons} wine={wineInfo} loading={loading} error={error} />
            <WineEntriesListBox
              wineEntries={wineProduction.wineEntries || []}
              removeWineEntries={service.removeWineEntry}
            />
            {wineProduction?.disposedAsWineEntry.length ? (
              <DisposedWineEntriesListBox wineEntries={wineProduction?.disposedAsWineEntry || []} />
            ) : null}
          </Col>
          <Col xl="4" lg="6">
            <WineProductionEventsListBox
              events={wineProduction?.events || []}
              removeEvent={service.removeEvent}
              loading={loading}
              entityId={wineProduction?.id}
              disabledActions
            />
          </Col>
          <Col xl="4" lg="6">
            <WineProductionIngredientsListBox
              ingredients={wineProduction?.ingredients || []}
              delIngredient={service.delIngredient}
              loading={loading}
              disabledActions
            />
          </Col>
        </Row>
      )}
      <div className="bg-gray-lighter rounded-lg">
        <WineHistory history={wineProduction?.history} />
      </div>
    </PageWrapper>
  );
};

export default WineProductionInfoPanel;
