import React from 'react';
import { withWineEntryToWineProductionServiceHOC } from './withWineEntryToWineProductionServiceHOC';
import { AddWineEntryFormPresentation } from './AddWineEntryFormPresentation';

export const AddWineEntryForm = () => {
  const WineEntryFormWithWineEntryToWineProductionService = withWineEntryToWineProductionServiceHOC(
    AddWineEntryFormPresentation
  );

  return <WineEntryFormWithWineEntryToWineProductionService />;
};
