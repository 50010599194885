import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { StorageSummaryData } from '../../../../types/dto';

export const StorageSummaryCard: React.FC<{ summary: StorageSummaryData }> = ({ summary }) => {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <CardBody>
          <small className="text-muted float-right">
            {t('storage.board.wines.table.liters.title')}
          </small>
          <Table>
            <tbody>
              <CommonRow
                label={t('storage.board.summary.table.totalLiters')}
                value={`${summary.totalLiters} l`}
                valueColumnClassName={'text-right'}
              />
              {summary.totalLitersInBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalLitersInBottles')}
                  value={`${summary.totalLitersInBottles} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalLitersInTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalLitersInTanks')}
                  value={`${summary.totalLitersInTanks} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalWhiteLiters ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalWhiteLiters')}
                  value={`${summary.totalWhiteLiters} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalRedLiters ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalRedLiters')}
                  value={`${summary.totalRedLiters} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalRoseLiters ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalRoseLiters')}
                  value={`${summary.totalRoseLiters} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalOrangeLiters ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalOrangeLiters')}
                  value={`${summary.totalOrangeLiters} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalOtherLiters ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalOtherLiters')}
                  value={`${summary.totalOtherLiters} l.`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.taxBandsTotalSummaryQuantity ? (
                <>
                  <CommonRow
                    label={t('storage.board.summary.table.taxBandsTotalSummaryQuantity')}
                    value={`${summary.taxBandsTotalSummaryQuantity} szt.`}
                    valueColumnClassName={'text-right'}
                  />
                  <CommonRow
                    label={t('storage.board.summary.table.taxBandsActualSummaryQuantity')}
                    value={`${summary.taxBandsActualSummaryQuantity} szt.`}
                    valueColumnClassName={'text-right'}
                  />
                </>
              ) : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <small className="text-muted float-right">
            {t('storage.board.wines.table.bottles.title')}
          </small>
          <Table>
            <tbody>
              {summary.totalBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalBottles')}
                  value={`${summary.totalBottles} szt.`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalWhiteLitersInBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalWhiteLitersInBottles')}
                  value={`${summary.totalWhiteBottles} szt. (${summary.totalWhiteLitersInBottles} l)`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalRedLitersInBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalRedLitersInBottles')}
                  value={`${summary.totalRedBottles} szt. (${summary.totalRedLitersInBottles} l)`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalRoseLitersInBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalRoseLitersInBottles')}
                  value={`${summary.totalRoseBottles} szt. (${summary.totalRoseLitersInBottles} l)`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalOrangeLitersInBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalOrangeLitersInBottles')}
                  value={`${summary.totalOrangeBottles} szt. (${summary.totalOrangeLitersInBottles} l)`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalOtherLitersInBottles ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalOtherLitersInBottles')}
                  value={`${summary.totalOtherBottles} szt. (${summary.totalOtherLitersInBottles} l)`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <small className="text-muted float-right">
            {t('storage.board.wines.table.tanks.title')}
          </small>
          <Table>
            <tbody>
              {summary.totalTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalTanks')}
                  value={`${summary.totalTanks} szt.`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalWhiteLitersInTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalWhiteLitersInTanks')}
                  value={`${summary.totalWhiteLitersInTanks} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalRedLitersInTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalRedLitersInTanks')}
                  value={`${summary.totalRedLitersInTanks} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalRoseLitersInTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalRoseLitersInTanks')}
                  value={`${summary.totalRoseLitersInTanks} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalOrangeLitersInTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalOrangeLitersInTanks')}
                  value={`${summary.totalOrangeLitersInTanks} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {summary.totalOtherLitersInTanks ? (
                <CommonRow
                  label={t('storage.board.summary.table.totalOtherLitersInTanks')}
                  value={`${summary.totalOtherLitersInTanks} l`}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};
