import React, { FC } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AddIngredientFormFields } from '../../applied/shared/AddIngredientFormFields';
import { IngredientFormProps } from '../../applied/shared/withIngredientServiceHOC';
import PageWrapper from '../../../../../../../layouts/PageWrapper';
import { Link } from 'react-router-dom';

const AddIngredientForm: FC<IngredientFormProps> = ({
  ingredient,
  actions: { onSubmit, updateIngredientSelect, updateTypeSelect, onChange, updateDate },
  error,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <PageWrapper loading={loading} disabled>
      <CardBody className={'pb-0'}>
        <AddIngredientFormFields
          ingredient={ingredient}
          actions={{ updateIngredientSelect, updateTypeSelect, onChange, updateDate }}
          error={error}
        />
        <div>
          <div className={'position-absolute pt-1'}>
            <Link
              to={{
                pathname: '/mv/ingredient/e/0',
                state: { from: window.location.hash?.split('#')?.[1] }
              }}
            >
              Stwórz nowy
            </Link>
          </div>
          <div className={'bg-secondary text-center'}>
            <Button color="info" className="btn-square" onClick={onSubmit.save}>
              {t('common.ADD')}
            </Button>
          </div>
        </div>
      </CardBody>
    </PageWrapper>
  );
};

export default AddIngredientForm;
