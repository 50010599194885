import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from './ContentWrapper';
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';
import PropTypes from 'prop-types';

const PageWrapper = ({ title, subtitle, loading, disabled, onClick, children }) => {
  const { t } = useTranslation();

  return (
    <>
      {disabled ? (
        <div className={loading ? 'whirl duo' : ''}>{children}</div>
      ) : (
        <ContentWrapper>
          <div
            className="content-heading"
            onClick={onClick}
            style={{ cursor: onClick ? 'pointer' : '' }}
          >
            <div>
              {t(title)}
              <small>{t(subtitle)}</small>
            </div>
          </div>
          <div style={{ height: loading ? '80vh' : '' }} className={loading ? 'whirl line' : ''}>
            {children}
          </div>
        </ContentWrapper>
      )}
    </>
  );
};

PageWrapper.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.any
};

export default PageWrapper;
