import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import {
  CommonListBox,
  ListBoxActions,
  ListBoxElementToShow
} from '../../../wine/components/WineInfo/list-box/shared/CommonListBox';
import { useWineProductionContext } from '../../context/WineProductionContext';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { FromApiConverter } from '../../../../../../services/Converters';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { WineEntry } from '../../../wine/types/WineProduction';

interface Props {
  wineEntries: WineEntry[];
  elementToShow: ListBoxElementToShow;
  actions: ListBoxActions;
}

const WineEntriesListBoxPresentation: React.FC<Props> = ({
  wineEntries,
  elementToShow,
  actions
}) => {
  const { t } = useTranslation();
  const { loading, wineProduction } = useWineProductionContext();

  return (
    <PageWrapper loading={loading} disabled>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wineProduction.info.WINE_ENTRIES')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}: {wineEntries?.length}
          </small>
          {wineEntries?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('common.NAME')}:`}
                  value={[`${t('common.LITERS')}:`, `${t('common.CREATED')}:`]}
                />
                {(wineEntries || []).map((wineEntry, key) => {
                  return (
                    <CommonListBox
                      header={[
                        wineEntry?.name,
                        wineEntry?.liters?.toString(),
                        FromApiConverter.convertDateTime?.(wineEntry?.addedToProduction)
                      ]}
                      actions={actions}
                      disableActions={wineProduction.liveStatus === EntityLiveStatus.ARCHIVED}
                      elementToSHow={elementToShow}
                      path={`${wineEntry?.entity}/info`}
                      elementId={wineEntry?.id}
                      infoElementId={wineEntry?.entryId}
                      dropdownInfo={{
                        paragraph: wineEntry?.entity === 'wine' ? 'Dodano moszcz' : 'Dodano wino'
                      }}
                      elementToShowKey={key}
                      key={key}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

export default WineEntriesListBoxPresentation;
