export enum SubscriptionType {
  BASIC = 'BASIC',
  NORMAL = 'NORMAL',
  PREMIUM = 'PREMIUM'
}

export enum SubscriptionStatus {
  ACTIVATED = 'ACTIVATED',
  PAST_DUE = 'PAST_DUE',
  DISABLED = 'DISABLED',
  UPGRADED = 'UPGRADED',
  TRIAL = 'TRIAL'
}

export enum SubscriptionPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export type Subscription = {
  type?: SubscriptionType;
  status?: SubscriptionStatus;
  period?: SubscriptionPeriod;
  validToDate?: string;
};
