import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { TaxBandsPlacement } from '../../../winery/components/wine_storage/types/WineStorage';

export const TaxBandsNeedAttentionCard: React.FC<{
  taxBandsPlacementsNeedAttention: TaxBandsPlacement[];
  title: string;
}> = ({ taxBandsPlacementsNeedAttention, title }) => {
  const { pushHistory } = usePushHistory();
  return (
    <Card>
      <CardBody>
        <small className="text-muted float-left pb-1">{title}</small>
        <small className="text-muted float-right">
          {taxBandsPlacementsNeedAttention.reduce((total, item) => total + item.quantity, 0)} szt.
        </small>
        <Table className={'table-hover'}>
          <tbody>
            {taxBandsPlacementsNeedAttention.map((taxBandsPlacement) => {
              return (
                <CommonRow
                  label={taxBandsPlacement.placementDate.toString()}
                  value={`${taxBandsPlacement.quantity} szt.`}
                  valueColumnClassName={'text-right text-warning'}
                  onClick={() =>
                    pushHistory(`/mv/wine_storage/info/${taxBandsPlacement.wineStorageId}`)
                  }
                  key={taxBandsPlacement.id}
                  style={{ cursor: 'pointer' }}
                />
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
