import React, { ChangeEvent, FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import InputElement from '../../../../../../components/common/InputElement';
import { ServiceError } from '../../../../../../types/Service';
import { Parcel } from '../../types/Parcel';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  error: ServiceError;
  parcel: Parcel;
  updateParcel: (e: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  onClickBack: () => void;
}

const SimpleParcelForm: FC<Props> = ({
  onSubmit,
  error,
  parcel,
  updateParcel,
  onClickBack,
  loading
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  return (
    <PageWrapper
      title={'parcel.TITLE'}
      subtitle={'parcel.LIST'}
      loading={loading}
      onClick={pushToBoard}
      disabled={false}
    >
      <Card>
        <CardBody>
          <div className={'pb-5'}>
            <h5 className="float-right">
              {parcel?.id ? t('sidebar.nav.element.EDIT') : t('sidebar.nav.element.ADD_NEW')}
            </h5>
          </div>
          <InputElement
            label={t('parcel.REGISTRATION_NUMBER')}
            type={'text'}
            name={'registrationNumber'}
            maxSize={'100'}
            onChange={updateParcel}
            defaultValue={parcel?.registrationNumber}
            error={error}
            disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('parcel.AREA')}
            type={'number'}
            name={'area'}
            maxSize={'100'}
            onChange={updateParcel}
            defaultValue={parcel?.area}
            error={error}
            disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('parcel.DISTRICT')}
            type={'text'}
            name={'district'}
            maxSize={'100'}
            onChange={updateParcel}
            defaultValue={parcel?.district}
            error={error}
            disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('parcel.COUNTRY')}
            type={'text'}
            name={'country'}
            maxSize={'100'}
            onChange={updateParcel}
            defaultValue={parcel?.country}
            error={error}
            optional
            disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('parcel.CITY')}
            type={'text'}
            name={'city'}
            maxSize={'100'}
            onChange={updateParcel}
            defaultValue={parcel?.city}
            error={error}
            optional
            disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('parcel.ZIP_CODE')}
            type={'text'}
            name={'zipCode'}
            maxSize={'100'}
            onChange={updateParcel}
            defaultValue={parcel?.zipCode}
            error={error}
            optional
            disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <FormErrorMessage error={error} messageType={'details'} />
          <div className="text-center bg-secondary">
            {parcel?.liveStatus === EntityLiveStatus.ARCHIVED ? (
              <Button color="info" className="btn-square" onClick={onClickBack}>
                {t('common.BACK')}
              </Button>
            ) : (
              <Button color="info" className="btn-square" onClick={onSubmit}>
                {parcel?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

export default SimpleParcelForm;
