import React from 'react';
import { useTranslation } from 'react-i18next';
import { WineStoragePresentationData } from '../../../../types/dto';
import usePagination from '../../../../hooks/usePagination';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { StorageSummaryWineRow } from './StorageSummaryWineRow';

export const StorageWinesListTable: React.FC<{
  wines: WineStoragePresentationData[];
  short?: boolean;
}> = ({ wines, short }) => {
  const { t } = useTranslation();
  const pagination = usePagination(wines, 10);

  const { pushHistory } = usePushHistory();

  function navigateToWine(id: number) {
    pushHistory(`/mv/wine_storage/info/${id}`);
  }

  return (
    <div className="card">
      <div className="card-body pb-1">
        <small className="text-muted float-right">{t('storage.board.wines.table.title')}</small>
        {/*<div className="float-left">*/}
        {/*	<label>*/}
        {/*		/!*<em className="fas fa-search"/>*!/*/}
        {/*		<input*/}
        {/*			type="search"*/}
        {/*			className="form-control form-control-sm"*/}
        {/*			placeholder="Szukaj"*/}
        {/*		/>*/}
        {/*	</label>*/}
        {/*</div>*/}
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row">
            <div className="col-sm-12 table-responsive">
              <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                <thead>
                  <tr role="row">
                    <th style={{ minWidth: '140px', width: 215 }}>
                      {t('storage.board.wines.table.name')}
                    </th>
                    <th style={{ width: 85 }}>{t('storage.board.wines.table.color')}</th>
                    <th style={{ width: 215 }}>{t('storage.board.wines.table.taste')}</th>
                    {!short ? (
                      <>
                        <th style={{ width: 135 }}>{t('storage.board.wines.table.bottles')}</th>
                        <th style={{ width: 135 }}>
                          {t('storage.board.wines.table.taxBandsActualQuantity')}
                        </th>
                        <th style={{ width: 135 }}>{t('storage.board.wines.table.liters')}</th>
                        <th style={{ width: 135 }}>{t('storage.board.wines.table.tanks')}</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {pagination.getCurrentResults().map((wine) => {
                    return (
                      <StorageSummaryWineRow
                        wine={wine}
                        onClick={() => navigateToWine(wine.id)}
                        key={wine.id}
                        short={short}
                      />
                    );
                  })}
                  {pagination.getCurrentResults().length === 0 ? (
                    <tr>
                      <td colSpan={100} className="text-center">
                        Brak danych
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent pagination={pagination} />
    </div>
  );
};
