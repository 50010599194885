import React, { useRef, useState } from 'react';
import { PlanSection } from './PlanSection';
import { PlanItem } from './PlanItem';
import { Plans } from '../data/Plans';
import { useAuthContext } from '../../platform/AuthContext';
import { useResponsiveContext } from '../../platform/ResponsiveContext';
import useSubscriptionService from '../hooks/useSubscriptionService';
import {
  Subscription,
  SubscriptionPeriod,
  SubscriptionStatus,
  SubscriptionType
} from '../../../types/Subscription';
import { usePushHistory } from '../../../hooks/usePushHistory';

export const PlansPage: React.FC<{ close?: () => void }> = ({ close }) => {
  const serviceRef = useRef(useSubscriptionService());
  const {
    subscriptionInfo: { activeSubscription: subscription, inProgressSubscription },
    setPrincipal
  } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { isMobile } = useResponsiveContext();
  const { pushHistory } = usePushHistory();

  function handleSelect(type: SubscriptionType, status: SubscriptionStatus) {
    setLoading(true);
    serviceRef.current
      .userChooseSubscription({ type, period: SubscriptionPeriod.MONTHLY, status } as Subscription)
      .then((response) => {
        setPrincipal(response);
        close?.();
        pushHistory('/mv/account');
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="">
      <div className="container container-lg pt-4">
        {!isMobile ? (
          <div className="text-center my-3 py-4">
            <div className="h2 text-bold">Wybierz plan</div>
            <p>Aby w pełni wykorzystać możliwości naszej aplikacji</p>
          </div>
        ) : null}
        <div className="row">
          {Plans.filter((plan) => {
            if (subscription) {
              return plan.id !== 0;
            }
            return true;
          }).map((plan) => {
            return (
              <div className={subscription ? 'col-lg-4' : 'col-lg-3'} key={plan.id}>
                <PlanSection
                  title={plan.title}
                  type={plan.type}
                  amount={plan.amount}
                  buttonLabel={plan.buttonLabel}
                  onSelect={() => handleSelect(plan.type, plan.status)}
                  color={plan.color}
                  key={plan.id}
                  status={subscription?.status}
                  currentPlan={subscription?.type}
                  planInProgress={inProgressSubscription?.type}
                  loading={false}
                >
                  {plan.items.map((item) => (
                    <PlanItem title={item.title} include={item.include} key={item.title} />
                  ))}
                </PlanSection>
              </div>
            );
          })}
        </div>
        <p className="text-center text-muted">
          <small></small>
        </p>
      </div>
    </div>
  );
};
