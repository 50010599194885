import useCrudeApi from '../../../lib/useCrudeApi';

const useSubscriptionService = () => {
  const { post: selectSubscription } = useCrudeApi<boolean>(`/ajax/subscription`);
  const { putBody: changeStatus } = useCrudeApi<boolean>(`/ajax/subscription/status`);
  const { putBody: confirm } = useCrudeApi<boolean>(`/ajax/subscription/confirm`);
  const { putBody: reject } = useCrudeApi<boolean>(`/ajax/subscription/reject`);
  const { put: increase } = useCrudeApi<boolean>(`/ajax/subscription/valid`);
  const { post: userChooseSubscription } = useCrudeApi<boolean>(`/ajax/subscription/user`);

  return {
    selectSubscription,
    userChooseSubscription,
    changeStatus,
    confirm,
    reject,
    increase
  };
};

export default useSubscriptionService;
