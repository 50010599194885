import React, { ChangeEvent, FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import InputElement from '../../../../../../components/common/InputElement';
import { ServiceError } from '../../../../../../types/Service';
import SelectHarvest from './SelectHarvest';
import SelectTank from './SelectTank';
import { Wine } from '../../types/Wine';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { RouterParams } from '../../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  updateHarvestInWine: () => void;
  updateTankInWine: () => void;
  error: ServiceError;
  wine: Wine;
  updateWine: (e: ChangeEvent<HTMLInputElement>) => void;
  updateDate: () => void;
  loading: boolean;
  onClickBack: () => void;
}

export const SimpleWineForm: FC<Props> = ({
  updateHarvestInWine,
  updateTankInWine,
  onSubmit,
  error,
  wine,
  updateWine,
  updateDate,
  loading,
  onClickBack
}) => {
  const { t } = useTranslation();
  const { harvestId, wineId } = useParams<RouterParams>();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/production`);
  }

  return (
    <PageWrapper
      title={'wine.TITLE'}
      subtitle={'wine.LIST'}
      onClick={pushToBoard}
      loading={loading}
    >
      <Card>
        <CardBody>
          <div className={'pb-5'}>
            <h5 className="float-right">
              {wine?.id ? t('sidebar.nav.element.EDIT') : t('sidebar.nav.element.ADD_NEW_WINE')}
            </h5>
          </div>
          <InputElement
            label={t('wine.NAME')}
            type={'text'}
            name={'name'}
            onChange={updateWine}
            defaultValue={wine?.name}
            error={error}
            disabled={wine?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputDate
            label={t('wine.DATE')}
            name={'startDate'}
            onChange={updateDate}
            defaultValue={wine?.startDate}
            error={error}
            disabled={wine?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <SelectTank
            value={wine?.tank || {}}
            name={'tank'}
            label={t('wine.TANK')}
            disabled={wine?.liveStatus === EntityLiveStatus.ARCHIVED}
            onChange={updateTankInWine}
            error={error}
          />
          <InputElement
            label={t('wine.LITERS')}
            type={'number'}
            name={'liters'}
            maxSize={'100'}
            onChange={updateWine}
            defaultValue={wine?.liters}
            error={error}
            // key={wine?.liters}
            disabled={!!wine?.id || wine?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          {((!harvestId && wineId === '0') || wine?.liveStatus === EntityLiveStatus.ARCHIVED) && (
            <SelectHarvest
              value={wine?.harvest || {}}
              name={'harvest'}
              label={t('wine.HARVEST')}
              disabled={
                wine?.harvest?.allDisposedToWine || wine?.liveStatus === EntityLiveStatus.ARCHIVED
              }
              onChange={updateHarvestInWine}
              error={error}
              showExtraMessage={
                wine?.harvest?.allDisposedToWine && wine?.liveStatus !== EntityLiveStatus.ARCHIVED
              }
            />
          )}
          <small className="text-muted">{t('wine.bottom.message')}</small>
          <FormErrorMessage error={error} messageType={'details'} />
          <div className="text-center bg-secondary mt-2">
            {wine?.liveStatus === EntityLiveStatus.ARCHIVED ? (
              <Button color="info" className="btn-square" onClick={onClickBack}>
                {t('common.BACK')}
              </Button>
            ) : (
              <Button color="info" className="btn-square" onClick={onSubmit}>
                {wine?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </PageWrapper>
  );
};
