import { History } from '../../../types/History';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../../../../../../layouts/ContentWrapper';
import TimelineSection from '../../../../../../../components/common/Timeline/TimelineSection';
import TimelineElement from '../../../../../../../components/common/Timeline/TimelineElement';
import PropTypes from 'prop-types';
import { Color } from '../../../../../../../components/common/enums/Color';
import { FromApiConverter } from '../../../../../../../services/Converters';
import { AuthContext } from '../../../../../../platform/AuthContext';
import { SubscriptionType } from '../../../../../../../types/Subscription';

interface Props {
  history?: History[];
}

const WineHistory: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation();
  const {
    subscriptionInfo: { activeSubscription },
    actions: { hasActiveSubscription }
  } = useContext(AuthContext);

  function getSubHeader(history: History) {
    if (history.name) return history.name;
    let result = '';
    if (history.actionType) {
      result = t(`history.ACTION_TYPE.${history?.actionType}`);
    }
    return result + (history?.message ? `: ${history?.message}` : '');
  }

  if (
    !hasActiveSubscription ||
    (activeSubscription?.type !== SubscriptionType.NORMAL &&
      activeSubscription?.type !== SubscriptionType.PREMIUM)
  ) {
    return null;
  }
  return (
    <ContentWrapper noBoarder>
      {history?.length ? (
        <TimelineSection>
          {history?.map((item, index) => {
            return (
              <TimelineElement
                historyItem={item}
                inverted={item?.status === 'APPLIED_INGREDIENT'}
                iconBg={item?.status === 'APPLIED_INGREDIENT' ? Color.Green : Color.Blue}
                key={index}
                header={t(`history.status.${item?.status}`)}
                date={
                  item?.status === 'CREATED'
                    ? FromApiConverter.convertDate(item?.createdItemDate)
                    : FromApiConverter.convertDateTime(item?.createdItemDate)
                }
                subHeader={getSubHeader(item)}
              />
            );
          })}
        </TimelineSection>
      ) : (
        <div className="text-center">Brak historii</div>
      )}
    </ContentWrapper>
  );
};

WineHistory.propTypes = {
  history: PropTypes.array.isRequired
};

export default WineHistory;
