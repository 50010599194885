import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import Pagination from '../../../../../../components/common/pagination/Pagination';
import ListActions from '../../../../../../components/common/ListActions';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { useParams } from 'react-router-dom';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { WineProductionPresentation } from '../../../wine/types/WineProduction';
import { RouterParams } from '../../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

const thead = [
  <th style={{ textAlign: 'center' }} key={1}>
    #
  </th>,
  <th style={{ textAlign: 'center' }} key={2}>
    <Trans i18nKey="wine.NAME" />
  </th>,
  <th style={{ textAlign: 'center' }} key={3}>
    <Trans i18nKey="wine.COLOR" />
  </th>,
  <th style={{ textAlign: 'center' }} key={4}>
    <Trans i18nKey="wine.TASTE" />
  </th>,
  <th style={{ textAlign: 'center' }} key={5}>
    <Trans i18nKey="wine.TANK_NUMBER" />
  </th>,
  <th style={{ textAlign: 'center' }} key={6}>
    <Trans i18nKey="wine.TANK_CAPACITY" />
  </th>,
  <th style={{ textAlign: 'center' }} key={7}>
    <Trans i18nKey="wine.LITERS" />
  </th>,
  <th style={{ textAlign: 'center' }} key={8}>
    <Trans i18nKey="common.ACTIONS" />
  </th>
];

const SimpleWineProductionList = ({
  wineProductions,
  page,
  pagination,
  limit,
  loading,
  paginationActions: { changePage, onPrev, onNext },
  entityActions: { remove, proceed, info, archive, revertArchive },
  wrapperDisabled,
  addWine,
  reloadHarvest,
  children,
  title,
  harvest
}) => {
  const { status } = useParams<RouterParams>();
  const { t } = useTranslation();

  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/production`);
  }

  const createTHead = () => {
    return (
      <thead>
        <tr>{thead.filter((th, index) => index < limit || index === thead.length - 1)}</tr>
      </thead>
    );
  };

  const buildRow = (wineProduction: WineProductionPresentation) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={1}>
        {wineProduction?.id}
      </td>,
      <td style={{ textAlign: 'center' }} key={2}>
        {wineProduction?.name}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {wineProduction?.wineColor ? t(`wine.COLOR.${wineProduction?.wineColor}`) : '-'}
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {wineProduction?.wineTaste ? t(`wine.TASTE.${wineProduction?.wineTaste}`) : '-'}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {wineProduction?.tankNumber}
      </td>,
      <td style={{ textAlign: 'center' }} key={6}>
        {wineProduction?.tankCapacity}
      </td>,
      <td style={{ textAlign: 'center' }} key={7}>
        {wineProduction?.liters}
      </td>,
      <td style={{ textAlign: 'center' }} key={8}>
        <ListActions
          entity={wineProduction}
          actions={{
            remove: remove,
            proceed: proceed,
            info: info,
            archive: archive,
            revertArchive: revertArchive
          }}
          triggerRemoveCallback={reloadHarvest}
        />
      </td>
    ];
    return (
      <tr key={wineProduction.id}>
        {fields.filter((th, index) => index < limit || index === thead.length - 1)}
      </tr>
    );
  };

  return (
    <PageWrapper
      title={'wineProduction.TITLE'}
      subtitle={'wineProduction.LIST'}
      loading={loading}
      onClick={pushToBoard}
      disabled={wrapperDisabled}
    >
      <Card>
        <CardBody>
          <h5 className="float-right">
            {title ||
              (status === EntityLiveStatus.ARCHIVED.toLowerCase()
                ? t('wineProduction.list.archived.TITLE')
                : t('wineProduction.list.created.TITLE'))}
          </h5>
          <Table hover>
            {createTHead()}
            <tbody>
              {(wineProductions || []).map((wineProduction) => buildRow(wineProduction))}
              {wineProductions && wineProductions.length === 0 && (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={8}>
                    {t('common.NO_DATA')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {children}
          {pagination.totalPages > 1 && (
            <Pagination
              page={page}
              pagination={pagination}
              actions={{
                changePage: changePage,
                prev: onPrev,
                next: onNext
              }}
            />
          )}
        </CardBody>
        {addWine && !harvest.allDisposedToWine && (
          <div className="card-footer text-center">
            <button type="button" className="btn btn-secondary btn-oval" onClick={addWine}>
              <Trans i18nKey="button.MAKE_WINE_PRODUCTION" />
            </button>
          </div>
        )}
      </Card>
    </PageWrapper>
  );
};

export default SimpleWineProductionList;
