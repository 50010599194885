import React from 'react';
import { ProductionEvent } from '../../../wine/types/Wine';
import { Service, ServiceError } from '../../../../../../types/Service';
import { useProductionEventOnClickService } from '../../hooks/useProductionEventOnClickService';
import { useGetProductionEvent } from '../../hooks/useGetProductionEvent';

export interface EventToWineProps {
  event: ProductionEvent;
  actions: {
    onChange: () => void;
    updateTypeSelect: () => void;
    onSubmit: {
      update: (e: React.MouseEvent<HTMLButtonElement>) => void;
      save: (e: React.MouseEvent<HTMLButtonElement>) => void;
    };
    updateDate: () => void;
    key?: string;
  };
  error: ServiceError;
  entity: 'wine' | 'wine_production';
}

export interface EventToWineServiceProps<T> {
  addEvent: (event: ProductionEvent) => Promise<T>;
  editEvent: (eventId: number, event: ProductionEvent) => Promise<T>;
  fieldName: 'wine' | 'wine_production';
  setResult: (value: Service<T>) => void;
  entity: 'wine' | 'wine_production';
}

export const withProductionEventToWineServiceHOC =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const { event, error } = useGetProductionEvent();
    const { addEvent, editEvent, fieldName, setResult } = props;
    const actions = useProductionEventOnClickService(addEvent, editEvent, fieldName, setResult);

    const newProps = {
      ...props,
      loading: actions.loading,
      event: event,
      error,
      actions
    };

    return <WrappedComponent {...newProps} />;
  };
