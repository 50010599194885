import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import useBoardService from '../../hooks/useBoardService';
import { StorageSummaryData } from '../../../../types/dto';
import { SelectedWines } from './SelectedWinesCard';
import { StorageSummaryCard } from './StorageSummaryCard';
import { StorageListCard } from './StorageListCard';
import { TaxBandsNeedAttentionCard } from './TaxBandsNeedAttentionCard';
import { useTranslation } from 'react-i18next';

export const StorageSummaryPage = () => {
  const serviceRef = useRef(useBoardService());
  const [summary, setSummary] = useState<StorageSummaryData>(null);
  const [loading, setLoading] = useState(true);
  const [favoriteWines, setFavoriteWines] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getStorage()
        .then((response) => {
          setLoading(false);
          setSummary(response);
          setFavoriteWines(response.wines.filter((wine) => wine.favoriteWine));
        })
        .catch(() => setLoading(false));
    }
  }, [loading, setSummary]);

  function getWines() {
    const wines = summary.wines;
    const winesNeedAttention = summary.winesNeedAttention;
    return wines.map((wine) => ({
      ...wine,
      taxBandsPlacementNeedAttention: !!winesNeedAttention.find((w) => w.id === wine.id)
        ?.taxBandsPlacementNeedAttention
    }));
  }

  if (summary === null) return <PageWrapper title={'home.STORAGE'} loading={loading} />;
  return (
    <PageWrapper title={'home.STORAGE'} loading={loading}>
      <div className={'row'}>
        <div className="col-lg-12">
          {favoriteWines.length > 0 ? <SelectedWines wines={favoriteWines} /> : null}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          {summary.taxBandsPlacementsNeedAttention?.length ? (
            <TaxBandsNeedAttentionCard
              title={t('storage.board.wines.table.taxBandsNeedAttention.title')}
              taxBandsPlacementsNeedAttention={summary.taxBandsPlacementsNeedAttention}
            />
          ) : null}
          {summary.taxBandsPlacementsNotProcessed?.length ? (
            <TaxBandsNeedAttentionCard
              title={t('storage.board.wines.table.taxBandsNotProcessed.title')}
              taxBandsPlacementsNeedAttention={summary.taxBandsPlacementsNotProcessed}
            />
          ) : null}
          <StorageSummaryCard summary={summary} />
        </div>
        <div className="col-lg-8">
          <StorageListCard wines={getWines()} />
        </div>
      </div>
    </PageWrapper>
  );
};
