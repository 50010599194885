import React, { FC } from 'react';

export const WelcomeCard: FC<{ children: React.ReactNode }> = ({ children }) => {
  const year = new Date().getFullYear();

  return (
    <div className="block-center mt-5 wd-xl" style={{ overflow: 'visible' }}>
      <div className="card card-flat">
        <div
          className="card-header text-center bg-dark"
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
        >
          <div>
            <img
              className="block-center rounded pl-5"
              src="img/logo-new.png"
              alt="Logo"
              style={{ maxHeight: '25px' }}
            />
          </div>
        </div>
        <div className="card-body">{children}</div>
      </div>
      <div className="p-3 text-center">
        <span className="mr-2">&copy;</span>
        <span>{year}</span>
        <span className="mx-2">-</span>
        <span>Winapp.pl</span>
        <br />
        <span>Aplikacja do zarządzania produkcją wina z upraw własnych</span>
      </div>
    </div>
  );
};
