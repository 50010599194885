import React, { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import InputElement from '../../../../../../components/common/InputElement';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { WineEntryToWineProductionProps } from './withWineEntryToWineProductionServiceHOC';
import { SelectWineEntryType } from './SelectWineEntryType';
import { SelectWineEntry } from './SelectWineEntry';
import { WineEntry } from '../../../wine/types/WineProduction';
import { useResponsiveContext } from '../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../hooks/useToggleAndGoToRef';

export const WineEntryFormFields: FC<WineEntryToWineProductionProps> = ({
  wineEntry,
  actions: { onChange, updateDate, updateSelect, updateSelectWineEntry, onSubmit, key },
  error
}) => {
  const { t } = useTranslation();

  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);

  function getEventStartingDate(wineEntry: WineEntry) {
    if (!wineEntry.addedToProduction) {
      wineEntry.addedToProduction = new Date();
    }
    return wineEntry.addedToProduction;
  }

  return (
    <Card key={key} innerRef={currentRef} className={isOpen ? '' : 'bg-info-dark'}>
      <CardBody className={'pt-2 pb-2'}>
        <div
          onClick={() => toggleOpen()}
          style={{ cursor: 'pointer', height: '1.5em' }}
          className={'pt-1'}
        >
          <i
            className="float-left"
            data-tool="card-collapse"
            data-toggle="tooltip"
            title=""
            data-original-title="Collapse Card"
          >
            <em className={isOpen ? 'fa fa-minus text-primary' : 'fa fa-plus'} />
          </i>
          <h5 className="m-0 float-right">
            {t(
              wineEntry?.id
                ? 'wineProduction.info.EDIT_WINE_ENTRY'
                : 'wineProduction.info.ADD_NEW_WINE_ENTRY'
            )}
          </h5>
        </div>
        {isOpen && (
          <div className={'mt-2'}>
            <SelectWineEntryType
              value={wineEntry?.entryType}
              name={'entryType'}
              label={t('wineEntry.ENTITY_TYPE')}
              onChange={updateSelect}
              error={error}
            />
            {wineEntry?.entryType && (
              <SelectWineEntry
                label={t('wineEntry.WINE_ENTRY')}
                onChange={updateSelectWineEntry}
                entryType={wineEntry?.entryType}
                key={wineEntry?.entryType}
                wineEntry={wineEntry}
                name={'wineEntry'}
                error={error}
              />
            )}
            <InputDate
              label={t('wineEntry.ADDED_TO_PRODUCTION')}
              name={'addedToProduction'}
              defaultValue={getEventStartingDate(wineEntry)}
              onChange={updateDate}
              error={error}
              showTimeSelect
            />
            <InputElement
              label={t('wineEntry.LITERS')}
              type={'number'}
              name={'liters'}
              defaultValue={wineEntry?.liters === 0 ? '' : wineEntry?.liters}
              onChange={onChange}
              error={error}
              maxSize={wineEntry?.liters?.toString()}
              className={'mb-2'}
              placeholder={
                wineEntry?.wine || wineEntry?.wineProduction
                  ? `Dostępne litry: ${
                      wineEntry?.wine?.liters || wineEntry?.wineProduction?.liters
                    }`
                  : ''
              }
            />
            {wineEntry?.wine || wineEntry?.wineProduction
              ? `Dostępne litry: ${wineEntry?.wine?.liters || wineEntry?.wineProduction?.liters}`
              : ''}
            <FormErrorMessage error={error} messageType={'details'} />
            <div className={'bg-secondary text-center pb-0'}>
              <Button
                color="info"
                className="btn-square btn-sm"
                onClick={wineEntry?.id ? onSubmit?.update : onSubmit?.save}
              >
                {wineEntry?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
