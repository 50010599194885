import React, { useRef } from 'react';
import { NavLink } from 'reactstrap';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { usePopupContext } from '../../../notifications/context/PopupContext';
import { PlansPage } from '../../../plans/components/PlansPage';
import { useAuthContext } from '../../../platform/AuthContext';
import { SubscriptionType } from '../../../../types/Subscription';

interface ColoredCardProps {
  color: string;
  icon: string;
  title: string;
  subtitle?: string;
  path: string;
  subscriptionRequired?: SubscriptionType;
  small?: boolean;
}

export const ColoredCard: React.FC<ColoredCardProps> = ({
  color,
  icon,
  title,
  subtitle,
  path,
  subscriptionRequired,
  small
}) => {
  const { pushHistory } = usePushHistory();
  const { showPopupComponent, closePopupComponent } = usePopupContext();
  const ref = useRef(<PlansPage close={closePopupComponent} />);

  const {
    subscriptionInfo: { activeSubscription }
  } = useAuthContext();

  function handleClick() {
    if (subscriptionRequired && subscriptionRequired !== activeSubscription?.type) {
      showPopupComponent(
        ref.current,
        <button className={'btn btn-info'} onClick={closePopupComponent}>
          Zamknij
        </button>
      );
    } else {
      pushHistory(path);
    }
  }

  return (
    <div
      className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3"
      onClick={handleClick}
      style={{ maxWidth: small ? '170px' : '240px', maxHeight: small ? '180px' : '240px' }}
    >
      <div className={`card card-hovered`} style={{ height: '80%', cursor: 'pointer' }}>
        <div className="card-body">
          <NavLink className="link-unstyled pb-0">
            <div className="row">
              <div className={`float-left text-${color}`}>
                <em className={`fa-2x fas ${icon} mb-2`} />
              </div>
            </div>
            <div className="row">
              <div className="float-left">
                <span className="h5">{title}</span>
              </div>
            </div>
            <div className="row">
              <div className="text-sm text-muted float-left">{subtitle}</div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
