import React, { FC } from 'react';
import { Card, CardBody, CardFooter, Table } from 'reactstrap';
import { WineStoragePresentationData } from '../../../../../../types/dto';
import { WineInfoButtons } from '../../../wine/components/WineInfo/WineInfoContainer';
import { ServiceError } from '../../../../../../types/Service';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import SmallTextBadge from '../../../../../../components/common/badges/SmallTextBadge';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import ProgressBar from '../../../../../../components/common/charts/ProgressBar';
import { PdfPageLayout } from '../../../../../../types/enums';
import log from 'loglevel';

const WineStorageInfoBox: FC<{
  buttons: WineInfoButtons;
  wine: WineStoragePresentationData;
  loading: boolean;
  error?: ServiceError;
}> = ({ buttons: { saveEditButton, archiveButton }, wine, loading, error }) => {
  const { t } = useTranslation();

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/WINE_IN_STORAGE?layout=${layout}&id=${wine.id}`;
  }

  return (
    <PageWrapper loading={loading} disabled>
      <Card>
        <CardBody className={'pb-0'}>
          {wine?.wineTaste && (
            <SmallTextBadge
              title={t(`wine.TASTE.${wine.wineTaste}`)}
              color={t(`wine_info_box.badge.color.${wine.wineTaste}`)}
            />
          )}
          {wine?.wineColor && (
            <SmallTextBadge
              title={t(`wine.COLOR.${wine.wineColor}`)}
              color={t(`wine_info_box.badge.color.${wine.wineColor}`)}
            />
          )}
          <h4 className="m-0">{wine?.name}</h4>
          {wine.totalLiters ? (
            <CardBody>
              <ProgressBar
                percent={Math.round((wine.totalLiters / wine.maxLiters) * 100)}
                color={'warning'}
                title={'Pozostało litrów'}
              />
            </CardBody>
          ) : null}
          <Table>
            <tbody>
              {wine.taxBandsPlacementNeedAttention ? (
                <CommonRow
                  label={'UWAGA'}
                  value={'Rozlicz banderole!'}
                  valueColumnClassName={'text-right text-warning'}
                />
              ) : null}
              <CommonRow
                label={'Litrów'}
                value={wine.totalLiters + ' l.'}
                valueColumnClassName={'text-right'}
              />
              {wine.totalBottles ? (
                <CommonRow
                  label={'Butelek'}
                  value={wine.totalBottles + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.litersInBottles ? (
                <CommonRow
                  label={'Litrów w butelkach'}
                  value={wine.litersInBottles + ' l.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.totalTanks ? (
                <CommonRow
                  label={'Zbiorników'}
                  value={wine.totalTanks + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.litersInTanks > 0 ? (
                <CommonRow
                  label={'Litrów w zbiornikach'}
                  value={wine.litersInTanks + ' l.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.taxBandsTotalQuantity ? (
                <CommonRow
                  label={'Naklejonych akcyz'}
                  value={wine.taxBandsTotalQuantity + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.taxBandsTotalQuantity ? (
                <CommonRow
                  label={'Pozostało z akcyzą'}
                  value={wine.taxBandsActualQuantity + ' szt.'}
                  valueColumnClassName={'text-right'}
                />
              ) : null}
              {wine.totalNotFinishedPlacementsOfTaxBands ? (
                <CommonRow
                  label={'Banderole do rozliczenia'}
                  value={wine.totalNotFinishedPlacementsOfTaxBands + ' szt.'}
                  valueColumnClassName={'text-right text-warning'}
                />
              ) : null}
              <CommonRow
                label={'Wino odmianowe'}
                value={wine.varietalWine ? 'Tak' : 'Nie'}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={'Wino z UE'}
                value={wine.fromUE ? 'Tak' : 'Nie'}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={'Wino z ChNP lub ChOG'}
                value={wine.protectedNameOrGeoWine ? 'Tak' : 'Nie'}
                valueColumnClassName={'text-right'}
              />
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="text-center bg-secondary">
          <button
            className="btn btn-secondary btn-sm ml-4"
            type="button"
            onClick={saveEditButton.action}
          >
            {saveEditButton.label}
          </button>
          {archiveButton?.action && (
            <button
              className="btn btn-warning ml-1 btn-sm"
              type="button"
              onClick={archiveButton?.action}
            >
              {archiveButton?.label}
            </button>
          )}
          <button
            className="btn btn-sm btn-secondary float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>
          </button>
          {error && <FormErrorMessage error={error} messageType={'details'} />}
        </CardFooter>
      </Card>
    </PageWrapper>
  );
};

export default WineStorageInfoBox;
