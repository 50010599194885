import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ParcelList from './components/ParcelList';
import { AuthContext } from '../../../platform/AuthContext';
import { ParcelForm } from './components/ParcelForm';
import ParcelProvider from './context/ParcelContext';

class ParcelApp extends Component {
  static contextType = AuthContext;

  render() {
    const { principal } = this.context;
    return (
      <>
        <ParcelProvider>
          <Switch>
            <Route exact path="/mv/parcel">
              <Redirect to="/mv/parcel/all" />
            </Route>
            <Route exact path="/mv/parcel/all" component={ParcelList} />
            <Route exact path="/mv/parcel/:status?" component={ParcelList} />
            <Route exact path="/mv/parcel/e/:parcelId">
              <ParcelForm principal={principal} />
            </Route>
          </Switch>
        </ParcelProvider>
      </>
    );
  }
}

export default ParcelApp;
