import React from 'react';
import WineProductionInfoPanel from './WineProductionInfoPanel';
import WineProductionInfoContainer from './WineProductionInfoContainer';

export const WineProductionInfo = () => {
  return (
    <WineProductionInfoContainer
      render={(buttons, updateTankInWineProduction, error) => (
        <WineProductionInfoPanel
          buttons={buttons}
          updateTankInWineProduction={updateTankInWineProduction}
          error={error}
        />
      )}
    />
  );
};
