import React, { useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useResponsiveContext } from '../../../features/platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../hooks/useToggleAndGoToRef';

const PanelTab = ({ title, tab1, tab2, tab1Label, tab2Label }) => {
  const [activeTab, setActiveTab] = useState('add');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);

  return (
    <Card innerRef={currentRef} className={isOpen ? '' : 'bg-info-dark'}>
      <CardBody className={'pt-2 pb-2'}>
        <div
          onClick={() => {
            activeTab !== 'info' && toggleOpen();
          }}
          style={{ cursor: 'pointer', height: '1.5em' }}
          className={'pt-1'}
        >
          <i
            className="float-left"
            data-tool="card-collapse"
            data-toggle="tooltip"
            title=""
            data-original-title="Collapse Card"
          >
            <em className={isOpen ? 'fa fa-minus text-primary' : 'fa fa-plus'} />
          </i>
          <h5 className="m-0 float-right">{title}</h5>
        </div>

        {isOpen && (
          <div className={'mt-2'}>
            <Nav pills justified className={'bg-secondary'}>
              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={
                    activeTab === 'add' ? 'active bg-info' : 'active bg-secondary text-dark'
                  }
                  onClick={() => {
                    toggleTab('add');
                  }}
                >
                  <em className="far icon-magnifier-add fa-fw" /> {tab1Label}
                </NavLink>
              </NavItem>
              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={
                    activeTab === 'info' ? 'active bg-info' : 'active bg-secondary text-dark'
                  }
                  onClick={() => {
                    toggleTab('info');
                  }}
                >
                  <em className="far icon-info fa-fw" /> {tab2Label}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="bg-white p-0">
              <TabPane tabId="add">{tab1}</TabPane>
              <TabPane tabId="info">{tab2}</TabPane>
            </TabContent>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default PanelTab;
