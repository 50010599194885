import React, { useRef, useState } from 'react';
import PageWrapper from '../../../layouts/PageWrapper';
import { useAuthContext } from '../../platform/AuthContext';
import useAccountService from '../hooks/useAccountService';
import { SubscriptionStatus, SubscriptionType } from '../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { usePopupContext } from '../../notifications/context/PopupContext';
import { PlansPage } from '../../plans/components/PlansPage';
import { usePushHistory } from '../../../hooks/usePushHistory';
import { useConfirmation } from '../../notifications/useConfirmation';
import { PastDuePlanInfo } from './plan_infos/PastDuePlanInfo';
import { ActivatedPlanInfo } from './plan_infos/ActivatedPlanInfo';
import { Plans } from '../../plans/data/Plans';
import { UpgradePlanInfo } from './plan_infos/UpgradePlanInfo';
import { TrialPlanInfo } from './plan_infos/TrialPlanInfo';
import { CurrentPlanInfo } from './plan_infos/CurrentPlanInfo';

export const AccountPage = () => {
  const {
    principal,
    subscriptionInfo: { activeSubscription: subscription, inProgressSubscription }
  } = useAuthContext();
  const service = useAccountService();
  const [name, setName] = useState(principal.name);
  const [companyName, setCompanyName] = useState(principal.companyName);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'1' | '2' | '3'>('3');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [removeAccountError, setRemoveAccountError] = useState('');
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const showConfirmation = useConfirmation();

  const { showPopupComponent, closePopupComponent } = usePopupContext();
  const ref = useRef(<PlansPage close={closePopupComponent} />);

  const { pushHistory } = usePushHistory();

  function handleSubmit() {
    if (name !== principal.name) {
      setLoading(true);
      service.updateUser({ name, login: principal.login }).finally(() => setLoading(false));
    }
    if (companyName !== principal.companyName) {
      setLoading(true);
      service.updateCompany({ name: companyName }).finally(() => setLoading(false));
    }
  }

  function submitDisabled() {
    return name === principal.name && companyName === principal.companyName;
  }

  function resetPasswordDisabled() {
    return !(oldPassword && newPassword && newPassword === confirmNewPassword);
  }

  function resetPassword() {
    if (!resetPasswordDisabled()) {
      setLoading(true);
      setError('');
      setSuccess(false);
      service
        .updatePassword({ newPassword, oldPassword })
        .then(() => {
          setSuccess(true);
          setOldPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
        })
        .catch((response) => setError(response.errors.newPassword || response.errors.oldPassword))
        .finally(() => setLoading(false));
    }
  }

  const removeAccount = (password: string) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ACCOUNT'),
      actionLabel: 'Usuń konto',
      actionCallback: () => handleRemoveAccount(password)
    });
  };

  function handleRemoveAccount(password: string) {
    setLoading(true);
    service
      .removeAccount({ password })
      .then((res) => {
        if (res) {
          pushHistory('/welcome/login');
        }
      })
      .catch((response) => setRemoveAccountError(response.errors.password))
      .finally(() => setLoading(false));
  }

  function getColor(type: SubscriptionType) {
    if (SubscriptionType.NORMAL === type) return 'text-green';
    if (SubscriptionType.PREMIUM === type) return 'text-yellow';
    return '';
  }

  function navigateToPlans() {
    setActiveTab('3');
    showPopupComponent(
      ref.current,
      <button className={'btn btn-info'} onClick={closePopupComponent}>
        Zamknij
      </button>
    );
  }

  function getSubheaderByStatus(status: SubscriptionStatus) {
    const chosenPlan = Plans.find(
      (plan) => plan.type === subscription?.type && plan.status !== SubscriptionStatus.TRIAL
    );
    switch (status) {
      case SubscriptionStatus.ACTIVATED:
        return (
          <ActivatedPlanInfo
            navigateToPlans={navigateToPlans}
            subscription={subscription}
            inProgressSubscription={inProgressSubscription}
            color={getColor(inProgressSubscription?.type)}
          />
        );
      case SubscriptionStatus.TRIAL:
        return (
          <TrialPlanInfo
            navigateToPlans={navigateToPlans}
            subscription={subscription}
            inProgressSubscription={inProgressSubscription}
            color={getColor(inProgressSubscription?.type)}
          />
        );
      case SubscriptionStatus.DISABLED:
        return 'disabled';
      case SubscriptionStatus.PAST_DUE:
        return (
          <PastDuePlanInfo
            navigateToPlans={navigateToPlans}
            price={chosenPlan.amount.toString()}
            inProgressSubscription={inProgressSubscription}
            color={getColor(inProgressSubscription?.type)}
            title={principal.login + ' - ' + subscription.type}
          />
        );
      case SubscriptionStatus.UPGRADED:
        return 'upgraded';
      default:
        return null;
    }
  }

  function getBodyByStatus(type: SubscriptionType) {
    switch (type) {
      case SubscriptionType.BASIC:
        return <UpgradePlanInfo subscriptionType={SubscriptionType.BASIC} />;
      case SubscriptionType.NORMAL:
        return <UpgradePlanInfo subscriptionType={SubscriptionType.NORMAL} />;
      case SubscriptionType.PREMIUM:
        return <CurrentPlanInfo subscriptionType={SubscriptionType.PREMIUM} />;
      default:
        return null;
    }
  }

  return (
    <PageWrapper title={'home.ACCOUNT'}>
      <PageWrapper loading={loading} disabled>
        <div className="container-md mx-0">
          <div className="row">
            <div className="col-lg-3">
              <div className="card">
                <div className="card-header bg-gray-lighter text-bold">Ustawienia osobiste</div>
                <div className="list-group">
                  <div
                    onClick={() => setActiveTab('3')}
                    className={
                      'list-group-item list-group-item-action ' +
                      (activeTab === '3' ? 'active' : '')
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    Plan
                  </div>
                  <div
                    onClick={() => setActiveTab('1')}
                    className={
                      'list-group-item list-group-item-action ' +
                      (activeTab === '1' ? 'active' : '')
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    Profil
                  </div>
                  <div
                    onClick={() => setActiveTab('2')}
                    className={
                      'list-group-item list-group-item-action ' +
                      (activeTab === '2' ? 'active' : '')
                    }
                    style={{
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    Konto
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="tab-content p-0 b0">
                <div className={'tab-pane ' + (activeTab === '1' ? 'active' : '')} id="tabSetting1">
                  <div className="card">
                    <div
                      className={
                        'card-header ' +
                        (subscription?.type === SubscriptionType.PREMIUM ? '' : 'pb-0')
                      }
                    >
                      <div className="text-bold float-left">Profil</div>
                      {subscription?.type ? (
                        <div className="float-right">
                          Twój plan:{' '}
                          <span>
                            <strong className={getColor(subscription.type)}>
                              {t(`plans.subscription.type.${subscription.type}`)}
                            </strong>
                          </span>{' '}
                          {subscription?.status !== SubscriptionStatus.ACTIVATED &&
                          subscription?.type !== SubscriptionType.BASIC ? (
                            <span>stracił ważność!</span>
                          ) : null}
                        </div>
                      ) : (
                        <div
                          className="float-right btn-link"
                          style={{ cursor: 'pointer' }}
                          onClick={navigateToPlans}
                        >
                          Wybierz plan
                        </div>
                      )}
                    </div>
                    {subscription?.type !== SubscriptionType.PREMIUM ||
                    subscription?.status !== SubscriptionStatus.ACTIVATED ? (
                      <div>
                        <div
                          className="pr-3 float-right btn-link"
                          onClick={navigateToPlans}
                          style={{ cursor: 'pointer' }}
                        >
                          {subscription?.status !== SubscriptionStatus.ACTIVATED
                            ? 'Zmień plan'
                            : 'Zwiększ plan'}
                        </div>
                      </div>
                    ) : null}
                    <div className="card-body">
                      <form action="#">
                        {/*<div className="form-group">*/}
                        {/*  <label>Picture</label>*/}
                        {/*  <input*/}
                        {/*    className="form-control filestyle"*/}
                        {/*    type="file"*/}
                        {/*    data-input="false"*/}
                        {/*    data-classbutton="btn btn-secondary"*/}
                        {/*    data-classinput="form-control inline"*/}
                        {/*    data-text="Upload new picture"*/}
                        {/*    data-icon="<span class='fa fa-upload mr-2'></span>"*/}
                        {/*    id="filestyle-0"*/}
                        {/*    tabIndex={-1}*/}
                        {/*    style={{*/}
                        {/*      position: 'absolute',*/}
                        {/*      clip: 'rect(0px, 0px, 0px, 0px)'*/}
                        {/*    }}*/}
                        {/*  />*/}
                        {/*  <div className="bootstrap-filestyle input-group">*/}
                        {/*    <div*/}
                        {/*      style={{*/}
                        {/*        position: 'absolute',*/}
                        {/*        width: '100%',*/}
                        {/*        height: '35.3906px',*/}
                        {/*        zIndex: -1*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <span className="group-span-filestyle " tabIndex={0}>*/}
                        {/*      <label*/}
                        {/*        htmlFor="filestyle-0"*/}
                        {/*        style={{ marginBottom: 0 }}*/}
                        {/*        className="btn btn-secondary "*/}
                        {/*      >*/}
                        {/*        <span className="fa fa-upload mr-2" />*/}
                        {/*        <span className="buttonText">Upload new picture</span>*/}
                        {/*      </label>*/}
                        {/*    </span>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        <div className="form-group">
                          <label>Właściciel</label>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label>Nazwa winnicy</label>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={principal.companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label>Login</label>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={principal.login}
                            disabled
                          />
                        </div>
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={handleSubmit}
                          disabled={submitDisabled()}
                        >
                          Zapisz ustawienia
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className={'tab-pane ' + (activeTab === '2' ? 'active' : '')} id="tabSetting2">
                  <div className="card">
                    <div
                      className={
                        'card-header ' +
                        (subscription?.type === SubscriptionType.PREMIUM ? '' : 'pb-0')
                      }
                    >
                      <div className="text-bold float-left">Konto</div>
                      {subscription?.type ? (
                        <div className="float-right">
                          Twój plan:{' '}
                          <span>
                            <strong className={getColor(subscription.type)}>
                              {t(`plans.subscription.type.${subscription.type}`)}
                            </strong>
                          </span>
                        </div>
                      ) : (
                        <div
                          className="float-right btn-link"
                          style={{ cursor: 'pointer' }}
                          onClick={navigateToPlans}
                        >
                          Wybierz plan
                        </div>
                      )}
                    </div>
                    {subscription?.type !== SubscriptionType.PREMIUM ? (
                      <div>
                        <div
                          className="pr-3 float-right btn-link"
                          onClick={navigateToPlans}
                          style={{ cursor: 'pointer' }}
                        >
                          Zwiększ plan
                        </div>
                      </div>
                    ) : null}
                    <div className="card-body">
                      <form action="#">
                        <div className="form-group">
                          <label>Obecne hasło</label>
                          <input
                            className="form-control"
                            type="password"
                            onChange={(e) => setOldPassword(e.target.value)}
                            key={success ? '5' : '6'}
                          />
                        </div>
                        <div className="form-group">
                          <label>Nowe hasło</label>
                          <input
                            className="form-control"
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            key={success ? '1' : '2'}
                          />
                        </div>
                        <div className="form-group">
                          <label>Powtórz nowe hasło</label>
                          <input
                            className="form-control required error"
                            type="password"
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            key={success ? '3' : '4'}
                          />
                        </div>
                        {error ? (
                          <p>
                            <small className="text-danger">
                              {error.split(',').map((message, index) => (
                                <>
                                  <span key={index}>{message}</span>
                                  <br />
                                </>
                              ))}
                            </small>
                          </p>
                        ) : null}
                        {success ? (
                          <p>
                            <small className="text-success">
                              Hasło zostało prawidłowo zmienione
                            </small>
                          </p>
                        ) : null}
                        <button
                          className="btn btn-info"
                          type="button"
                          onClick={resetPassword}
                          disabled={resetPasswordDisabled()}
                        >
                          Zaktualizuj hasło
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header bg-danger text-bold"
                      style={{
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px'
                      }}
                    >
                      Usuń konto
                    </div>
                    <div className="card-body bt">
                      <p>
                        Podaj hasło i naciśnij przycisk. Następnie zostaniesz poproszony o
                        potwierdzenie swojej decyzji.
                      </p>
                      <div className="form-group">
                        <label>Hasło</label>
                        <input
                          className="form-control"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          key={success ? '7' : '8'}
                        />
                        {removeAccountError ? (
                          <small className="text-danger">
                            {removeAccountError.split(',').map((message, index) => (
                              <>
                                <span key={index}>{message}</span>
                                <br />
                              </>
                            ))}
                          </small>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => removeAccount(password)}
                        disabled={!password.length}
                      >
                        <span className="text-danger">Usuń konto</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className={'tab-pane ' + (activeTab === '3' ? 'active' : '')} id="tabSetting3">
                  <div className="card">
                    <div className="text-center my-3 py-4 px-3">
                      <div className="h2 text-bold">
                        <strong
                          className={getColor(subscription?.type || inProgressSubscription?.type)}
                        >
                          {t(
                            `plans.subscription.type.${
                              subscription?.type || inProgressSubscription?.type
                            }`
                          )}
                        </strong>
                      </div>
                      {getSubheaderByStatus(subscription?.status || inProgressSubscription?.status)}
                      {getBodyByStatus(subscription?.type || inProgressSubscription?.type)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </PageWrapper>
  );
};
