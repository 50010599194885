import { SubscriptionType } from './types/Subscription';

const Menu = [
  {
    heading: 'Start',
    translate: 'sidebar.heading.START'
  },
  {
    name: 'Start',
    icon: 'icon-grid',
    path: '/mv/board/start',
    translate: 'sidebar.nav.element.START'
  },
  {
    name: 'Winnica',
    icon: 'icon-directions',
    path: '/mv/board/summary',
    translate: 'sidebar.nav.element.VINEYARD_SUMMARY'
  },
  {
    name: 'Winiarnia',
    icon: 'icon-screen-tablet',
    translate: 'sidebar.nav.element.WINERY_SUMMARY',
    path: '/mv/board/production'
  },
  {
    name: 'Piwnica',
    icon: 'icon-login',
    translate: 'sidebar.nav.element.WINERY_STORAGE',
    path: '/mv/board/storage',
    type: SubscriptionType.PREMIUM
  },
  {
    name: 'Raporty',
    icon: 'icon-docs',
    translate: 'sidebar.nav.element.REPORTS',
    path: '/mv/reports',
    type: SubscriptionType.PREMIUM
  },
  {
    heading: 'Winnica',
    translate: 'sidebar.heading.VINEYARD'
  },
  {
    name: 'Działki ewidencyjne',
    icon: 'icon-picture',
    translate: 'sidebar.nav.PARCELS',
    submenu: [
      {
        name: 'Lista działek',
        path: '/mv/parcel/all',
        translate: 'sidebar.nav.element.PARCEL_LIST'
      },
      {
        name: 'Dodaj nową',
        path: '/mv/parcel/e/0',
        translate: 'sidebar.nav.element.ADD_NEW'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/parcel/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  },
  {
    name: 'Winorośla',
    icon: 'icon-equalizer',
    translate: 'sidebar.nav.GRAPEVINES',
    submenu: [
      {
        name: 'Lista winorośli',
        path: '/mv/grapevine/all',
        translate: 'sidebar.nav.element.GRAPEVINE_LIST'
      },
      {
        name: 'Dodaj nową',
        path: '/mv/grapevine/e/0',
        translate: 'sidebar.nav.element.ADD_NEW'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/grapevine/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  },
  {
    name: 'Zbiory',
    icon: 'icon-basket-loaded',
    translate: 'sidebar.nav.HARVESTS',
    submenu: [
      {
        name: 'Lista zbiorów',
        path: '/mv/harvest/all',
        translate: 'sidebar.nav.element.HARVEST_LIST'
      },
      {
        name: 'Dodaj nowy',
        path: '/mv/harvest/e/0',
        translate: 'sidebar.nav.element.ADD_NEW_HARVEST'
      },
      {
        name: 'Dodaj nowy',
        path: '/mv/grapes/e/0',
        translate: 'sidebar.nav.element.ADD_GRAPES'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/harvest/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  },
  {
    heading: 'Winiarnia',
    translate: 'sidebar.heading.WINERY'
  },
  {
    name: 'Nastawy',
    icon: 'icon-drop',
    translate: 'sidebar.nav.WINES',
    submenu: [
      {
        name: 'Lista nastawów',
        path: '/mv/wine/all',
        translate: 'sidebar.nav.element.WINE_LIST'
      },
      {
        name: 'Dodaj nowy',
        path: '/mv/wine/e/0',
        translate: 'sidebar.nav.element.ADD_NEW_WINE'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/wine/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  },
  {
    name: 'Wina w produkcji',
    icon: 'icon-pie-chart',
    translate: 'sidebar.nav.WINE_PRODUCTION',
    submenu: [
      {
        name: 'Lista win',
        path: '/mv/wine_production/all',
        translate: 'sidebar.nav.element.WINE_PRODUCTION_LIST'
      },
      {
        name: 'Dodaj nowe',
        path: '/mv/wine_production/e/0',
        translate: 'sidebar.nav.element.ADD_NEW_WINE_PRODUCTION'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/wine_production/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  },
  {
    name: 'Zbiorniki',
    icon: 'icon-screen-smartphone',
    translate: 'sidebar.nav.TANKS',
    submenu: [
      {
        name: 'Przegląd zbiorników',
        path: '/mv/board/tanks',
        translate: 'sidebar.nav.element.TANKS_BOARD'
      },
      {
        name: 'Lista zbiorników',
        path: '/mv/tank/all',
        translate: 'sidebar.nav.element.TANKS_LIST'
      },
      {
        name: 'Dodaj nowy',
        path: '/mv/tank/e/0',
        translate: 'sidebar.nav.element.ADD_NEW_TANK'
      }
    ]
  },
  {
    name: 'Dodatki',
    icon: 'icon-chemistry',
    translate: 'sidebar.nav.INGREDIENTS',
    submenu: [
      {
        name: 'Przegląd dodatków',
        path: '/mv/board/ingredients',
        translate: 'sidebar.nav.element.INGREDIENTS_BOARD'
      },
      {
        name: 'Lista dodatków',
        path: '/mv/ingredient/all',
        translate: 'sidebar.nav.element.INGREDIENTS_LIST'
      },
      {
        name: 'Dodaj nowy',
        path: '/mv/ingredient/e/0',
        translate: 'sidebar.nav.element.ADD_NEW_INGREDIENT'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/ingredient/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  },
  {
    heading: 'Piwnica',
    translate: 'sidebar.heading.STORAGE'
  },
  {
    name: 'Piwnica',
    icon: 'icon-home',
    translate: 'sidebar.nav.STORAGE',
    type: SubscriptionType.PREMIUM,
    submenu: [
      {
        name: 'Lista win',
        path: '/mv/wine_storage/all',
        translate: 'sidebar.nav.element.WINE_STORAGE_LIST'
      },
      {
        name: 'Dodaj nowe',
        path: '/mv/wine_storage/e/0',
        translate: 'sidebar.nav.element.ADD_NEW_WINE_STORAGE'
      },
      {
        name: 'Zarchiwizowane',
        path: '/mv/wine_storage/archived',
        translate: 'sidebar.nav.element.ARCHIVED'
      }
    ]
  }
];

export default Menu;
