import React from 'react';
import { Progress } from 'reactstrap';
import { Wine } from '../../../../wine/types/Wine';

interface IProps {
  wine: Wine;
  onClick: () => void;
  hasAnyTank: boolean;
}

export const WineInHarvestRow: React.FC<IProps> = ({ wine, onClick, hasAnyTank }) => {
  const barColor = (wine: Wine) => {
    if (wine?.tank?.percentOfUsage > 75 && wine?.tank?.percentOfUsage <= 85) {
      return 'warning';
    } else if (wine?.tank?.percentOfUsage > 85) {
      return 'danger';
    }
    return 'info';
  };

  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }} onClick={onClick}>
      <td>{wine.name}</td>
      <td>{wine.startDate}</td>
      <td>{wine.startLiters} l.</td>
      <td>{wine.pressWasteLiters} l.</td>
      <td>{wine.wasteLiters} l.</td>
      <td>{wine.endLiters} l.</td>
      <td>{wine.disposedLiters} l.</td>
      <td>{wine.liters} l.</td>
      {hasAnyTank ? (
        <td>
          {wine?.tank?.percentOfUsage ? (
            <div className="d-flex align-items-center">
              <div className="w-100">
                <Progress
                  className="progress-xs m-0"
                  value={wine?.tank?.percentOfUsage}
                  color={barColor(wine)}
                />
              </div>
              <div className="wd-xxs text-right">
                <div className="text-bold text-muted">{wine?.tank?.percentOfUsage || '0'}%</div>
              </div>
            </div>
          ) : null}
        </td>
      ) : null}
      {hasAnyTank ? <td>{wine?.tank?.number}</td> : null}
    </tr>
  );
};
