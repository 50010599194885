import React from 'react';
import { FormGroup } from 'reactstrap';
import Select from 'react-select';
import { SelectOption } from '../../../../types/SelectOption';
import { SelectOptionProps } from './SelectTypeModel';
import PageWrapper from '../../../../layouts/PageWrapper';

interface Props {
  receiver: (value: unknown) => {
    options: SelectOption[];
    selected: string | SelectOption;
    loading: boolean;
  };
  disabled?: boolean;
  clazzName?: string;
}

export const SelectType: React.FC<SelectOptionProps<unknown> & Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  error,
  receiver,
  disabled,
  errorName,
  clazzName
}) => {
  const { options, selected: value, loading } = receiver(selected);

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: '#d92550',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center right calc(2.25rem / 4)',
      backgroundSize: 'calc(2.25rem / 2) calc(2.25rem / 2)'
    })
  };

  const errorFieldName = errorName || name;

  return (
    <fieldset className={clazzName}>
      <FormGroup row>
        {label && (
          <label className="col-md-2 col-form-label pr-0" htmlFor={name}>
            {label}
            <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
              {' '}
              *
            </span>
          </label>
        )}
        <div className={label ? 'col-md-10' : 'col-md-12'}>
          <PageWrapper loading={loading} disabled>
            <Select
              name={name}
              options={options}
              onChange={(s) => onChange(name, s)}
              value={value}
              placeholder={'Wybierz'}
              styles={error?.hasError?.(errorFieldName) && customStyles}
              isDisabled={disabled}
            />
          </PageWrapper>
          <span
            className="invalid-feedback"
            style={{ display: error?.hasError?.(errorFieldName) ? 'block' : 'none' }}
          >
            {error?.getErrorMessage?.(errorFieldName)}
          </span>
        </div>
      </FormGroup>
    </fieldset>
  );
};
