import { History } from '../../../wine/types/History';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from '../../../../../../layouts/ContentWrapper';
import TimelineSection from '../../../../../../components/common/Timeline/TimelineSection';
import TimelineElement from '../../../../../../components/common/Timeline/TimelineElement';
import PropTypes from 'prop-types';
import { Color } from '../../../../../../components/common/enums/Color';
import { FromApiConverter } from '../../../../../../services/Converters';
import { AuthContext } from '../../../../../platform/AuthContext';
import { SubscriptionType } from '../../../../../../types/Subscription';

interface Props {
  history?: History[];
}

const HarvestHistory: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation();
  const {
    subscription,
    actions: { hasActiveSubscription }
  } = useContext(AuthContext);

  if (
    !hasActiveSubscription ||
    (subscription?.type !== SubscriptionType.NORMAL &&
      subscription?.type !== SubscriptionType.PREMIUM)
  ) {
    return null;
  }
  return (
    <ContentWrapper noBoarder>
      {history?.length ? (
        <TimelineSection>
          {history?.map((item, index) => {
            const subHeader =
              (item?.actionType ? item?.actionType + ' ' : '') +
              (item?.message ? item?.message : '');
            return (
              <TimelineElement
                historyItem={item}
                inverted={item?.status === 'ADDED_WINE'}
                key={index}
                iconBg={item?.status === 'ADDED_WINE' ? Color.Green : Color.Blue}
                header={t(`history.status.${item?.status}`)}
                date={
                  item?.status === 'CREATED' || item?.status === 'ADDED_WINE'
                    ? FromApiConverter.convertDate(item?.createdItemDate)
                    : FromApiConverter.convertDateTime(item?.createdItemDate)
                }
                subHeader={subHeader}
              />
            );
          })}
        </TimelineSection>
      ) : (
        <div className="text-center">Brak historii</div>
      )}
    </ContentWrapper>
  );
};

HarvestHistory.propTypes = {
  history: PropTypes.array.isRequired
};

export default HarvestHistory;
