import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { UserInfoData } from '../../types';
import useAdminService from '../../hooks/useAdminService';
import { UsersTable } from './UsersTable';

export const UsersPage = () => {
  const [users, setUsers] = useState<UserInfoData[]>([]);
  const serviceRef = useRef(useAdminService());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getUsersList()
        .then((response) => {
          setUsers(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, setUsers]);

  return (
    <PageWrapper title={'admin.PAGE'} subtitle={'admin.USERS'}>
      <UsersTable users={users} loading={loading} />
    </PageWrapper>
  );
};
