import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wine } from '../../../../wine/types/Wine';
import usePagination from '../../../../../../../hooks/usePagination';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';
import { WineInHarvestRow } from './WineInHarvestRow';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';
import PageWrapper from '../../../../../../../layouts/PageWrapper';

export const WinesInHarvestListTable: React.FC<{
  wines: Wine[];
  loading: boolean;
}> = ({ wines, loading }) => {
  const { t } = useTranslation();
  const pagination = usePagination(wines, 10);

  const { pushHistory } = usePushHistory();

  function navigateToWine(id: number) {
    pushHistory(`/mv/wine/info/${id}`);
  }

  const hasAnyTank = pagination.getCurrentResults().filter((wine) => !!wine.tank).length > 0;

  return (
    <PageWrapper loading={loading} disabled>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('harvest.wines.table.title')}</small>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                  <thead>
                    <tr role="row">
                      <th style={{ minWidth: '140px', width: 200 }}>
                        {t('winery.board.wines.table.name')}
                      </th>
                      <th style={{ width: 85 }}>{t('wine.DATE')}</th>
                      <th style={{ width: 85 }}>{t('winery.board.wines.table.startLiters')}</th>
                      <th style={{ width: 85 }}>{t('winery.board.wines.table.restLiters')}</th>
                      <th style={{ width: 85 }}>{t('winery.board.wines.table.wasteLiters')}</th>
                      <th style={{ width: 85 }}>{t('winery.board.wines.table.endLiters')}</th>
                      <th style={{ width: 85 }}>{t('winery.board.wines.table.disposedLiters')}</th>
                      <th style={{ width: 85 }}>{t('winery.board.wines.table.liters')}</th>
                      {hasAnyTank ? (
                        <th style={{ width: 215 }}>{t('winery.board.wines.table.percentage')}</th>
                      ) : null}
                      {hasAnyTank ? (
                        <th style={{ width: 100 }}>{t('winery.board.wines.table.tank')}</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((wine) => {
                      return (
                        <WineInHarvestRow
                          wine={wine}
                          onClick={() => navigateToWine(wine.id)}
                          key={wine.id}
                          hasAnyTank={hasAnyTank}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </PageWrapper>
  );
};
