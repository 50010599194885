import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import { AuthService } from './AuthService';
import Login from '../../pages/Login';
import WelcomeApp from '../welcome/WelcomeApp';
import Lock from '../../pages/Lock';
import WineryApp from '../winery/components/WineryApp';
import * as PropTypes from 'prop-types';
import log from 'loglevel';
import Recover from '../../pages/Recover';
import Reset from '../../pages/Reset';
import ErrorApp from '../error/ErrorApp';
import { AdminApp } from '../admin/AdminApp';
import Register from '../../pages/Register';
import { PlansPage } from '../plans/components/PlansPage';

const AppRouting = ({ location: { pathname }, history }) => {
  const [error, setError] = useState('');
  const { principal, setPrincipal, subscriptionInfo, lock, actions } = useAuthContext();

  const redirectToLogin = () => {
    return (
      <Redirect
        to={{
          pathname: '/welcome/login',
          state: {
            referrer: pathname
          }
        }}
      />
    );
  };

  useEffect(() => {
    log.debug('AppRouting::render', error, principal);
    AuthService.getUserInfo()
      .then((res) => setPrincipal(res))
      .catch((res) => {
        setError(res);
        history.push(`/mv/error`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [history.location]);

  if (error) {
    return (
      <Switch>
        <Route path="/mv/error" component={ErrorApp} />
      </Switch>
    );
  }
  console.log({ subscriptionInfo });
  if (
    principal?.login &&
    !actions.isAdmin &&
    !subscriptionInfo.activeSubscription &&
    !subscriptionInfo.inProgressSubscription
  ) {
    return (
      <Switch>
        <Route path="/mv/plans" component={PlansPage} />
        <Redirect to={'/mv/plans'} />
      </Switch>
    );
  }
  return error || principal ? (
    <Switch>
      <Route exact path={'/'}>
        {principal?.login && !lock ? (
          <Redirect to={principal.realms[0]} />
        ) : (
          <Redirect to={'/welcome/login'} />
        )}
      </Route>
      <Route path="/recover" component={Recover} />
      <Route path="/welcome/reset/:token?" component={Reset} />
      <Route path="/welcome/login" component={Login} />
      <Route path="/welcome/hregister" component={Register} />
      <Route path="/lock" component={Lock} />
      <Route path="/welcome">
        {principal?.login ? <Redirect to={principal.realms[0]} /> : <WelcomeApp />}
      </Route>
      <Route path="/mv">{principal?.login ? <WineryApp /> : redirectToLogin()}</Route>
      <Route path="/admin_console">{actions.isAdmin ? <AdminApp /> : redirectToLogin()}</Route>
    </Switch>
  ) : (
    <></>
  );
};

AppRouting.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(AppRouting);
