import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Harvest } from '../../types/Harvest';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { ServiceError } from '../../../../../../types/Service';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import SimpleInputElement from '../../../../../../components/common/SimpleInputElement';

interface IProps {
  harvest: Harvest;
  dispose: (e: React.MouseEvent<HTMLButtonElement>, value: boolean) => void;
  edit: () => void;
  updateBox: (name: string, value: string) => void;
  addBoxToHarvest: (e: React.MouseEvent<HTMLInputElement>) => void;
  error: ServiceError;
}
export const HarvestSummaryCard: React.FC<IProps> = ({
  harvest,
  dispose,
  edit,
  updateBox,
  addBoxToHarvest,
  error
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <div className={'card-header ' + (harvest.allDisposedToWine ? '' : 'pb-0')}>
        <div className="card-title">
          {(harvest?.grapevine?.name || harvest.varietyName) + ' '}{' '}
          <span className="text-muted">{harvest?.dateOfHarvest?.toString()}</span>
        </div>
      </div>
      {!harvest.fromOtherVineyard && !harvest?.allDisposedToWine ? (
        <Card className={'mb-0'}>
          <CardHeader>
            <CardBody className={'pt-0'}>
              <div className={'row'}>
                <div className="col-6 float-left p-0">
                  <SimpleInputElement
                    type="number"
                    name="weightOfFullBox"
                    maxSize="100"
                    onChange={(e) => updateBox(e.target.name, e.target.value)}
                    disabled={harvest?.allDisposedToWine}
                    autoFocus={true}
                    onKeyPress={(e) => e.key === 'Enter' && addBoxToHarvest?.(e)}
                  />
                </div>
                <div className="col-6 float-right pr-0">
                  <Button
                    color="info"
                    disabled={harvest?.allDisposedToWine}
                    className="btn-square"
                    style={{ width: '100%' }}
                    onClick={addBoxToHarvest}
                  >
                    Dodaj kg
                  </Button>
                </div>
              </div>
              <FormErrorMessage
                error={error}
                messageType={'fieldError'}
                fieldName={'weightOfFullBox'}
              />
            </CardBody>
          </CardHeader>
        </Card>
      ) : null}
      <Table>
        <tbody>
          <CommonRow label={t('harvest.WEIGHT_OF_GRAPES')} value={`${harvest.weightOfGrapes}`} />
          <CommonRow label={t('harvest.AMOUNT_OF_MUST')} value={`${harvest.amountOfMust}`} />
          {harvest.amountOfWaste ? (
            <CommonRow label={t('harvest.AMOUNT_OF_WASTE')} value={`${harvest.amountOfWaste}`} />
          ) : null}
        </tbody>
      </Table>
      <Card className={'mb-0'}>
        <CardHeader>
          {!harvest?.allDisposedToWine ? (
            <div className="text-center">
              <button type="button" className="btn-square btn btn-secondary" onClick={edit}>
                {t('harvest.EDIT')}
              </button>
              <button
                type="button"
                disabled={harvest?.allDisposedToWine}
                className="btn-square btn btn-warning ml-2"
                onClick={(e) => dispose?.(e, true)}
              >
                Przelicz i zamknij
              </button>
            </div>
          ) : null}
          {harvest?.allDisposedToWine && (
            <div className="bg-secondary text-center">
              <div>
                <button
                  className="btn-square btn btn-warning"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => dispose?.(e, false)}
                >
                  Cofnij zamknięcie
                </button>
              </div>
            </div>
          )}
          {error ? (
            <div className="text-center">
              <FormErrorMessage error={error} messageType={'details'} />
            </div>
          ) : null}
        </CardHeader>
      </Card>
    </Card>
  );
};
