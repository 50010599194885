import { PlanSectionType } from '../components/PlanSection';
import { PlanItemType } from '../components/PlanItem';
import { SubscriptionStatus, SubscriptionType } from '../../../types/Subscription';

type Plan = PlanSectionType & { id: number; items: Array<PlanItemType> };

const items: PlanItemConfig[] = [
  ['Winnica', true, true, true],
  ['Winiarnia', true, true, true],
  ['Nielimitowane wina', false, true, true],
  ['Brak reklam', false, true, true],
  // ['Personalizacja wyglądu strony', false, true, true],
  ['Historia tworzonych win', false, true, true],
  ['Podstawowe raporty PDF', false, true, true],
  ['Rozbudowane Raporty PDF', false, false, true],
  ['Magazyn / Piwnica', false, false, true],
  ['Rejestr banderolowania', false, false, true],
  ['Raporty PDF z magazynu', false, false, true],
  ['Deklaracje KOWR', false, false, true]
];

type PlanItemConfig = [string, boolean, boolean, boolean];

type PlanConfig = {
  id: number;
  title: string;
  type: SubscriptionType;
  status?: SubscriptionStatus;
  amount: number;
  buttonLabel: string;
  color: 'base' | 'standard' | 'premium';
  items: PlanItemConfig[];
};

const createPlanItem = (config: PlanItemConfig): PlanItemType => ({
  title: config[0],
  include: config[1]
});

const createPlan = (config: PlanConfig): Plan => ({
  id: config.id,
  title: config.title,
  type: config.type,
  status: config.status,
  amount: config.amount,
  buttonLabel: config.buttonLabel,
  color: config.color,
  items: config.items.map(createPlanItem)
});

const planConfigs: PlanConfig[] = [
  {
    id: 0,
    title: 'PREMIUM PRZEZ MIESIĄC',
    type: SubscriptionType.PREMIUM,
    status: SubscriptionStatus.TRIAL,
    amount: 0,
    buttonLabel: 'Wybieram premium !',
    color: 'premium',
    items: items.map((item) => [item[0], item[3], item[3], item[3]])
  },
  {
    id: 1,
    title: 'PODSTAWOWY',
    type: SubscriptionType.BASIC,
    amount: 0,
    buttonLabel: 'Wybieram podstawowy',
    color: 'base',
    items: items.map((item) => [item[0], item[1], item[2], item[3]])
  },
  {
    id: 2,
    title: 'DLA WINIARZA',
    type: SubscriptionType.NORMAL,
    amount: 29.99,
    buttonLabel: 'Wybieram rozszerzony',
    color: 'standard',
    items: items.map((item) => [item[0], item[2], item[2], item[3]])
  },
  {
    id: 3,
    title: 'PREMIUM DLA WINNIC',
    type: SubscriptionType.PREMIUM,
    amount: 49.99,
    buttonLabel: 'Wybieram premium !',
    color: 'premium',
    items: items.map((item) => [item[0], item[3], item[3], item[3]])
  }
];

export const Plans: Plan[] = planConfigs.map(createPlan);
