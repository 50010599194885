import React from 'react';
import { useHarvestContext } from '../../context/HarvestContext';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { HarvestSummaryCard } from './HarvestSummaryCard';
import { WinesInHarvestListCard } from './wines/WinesInHarvestListCard';
import { BoxesInHarvestListCard } from './boxes/BoxesInHarvestListCard';
import HarvestHistory from '../HarvestInfo/HarvestHistory';
import { useHarvestInfo } from './hooks/useHarvestInfo';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

export const HarvestInfoRedesigned = () => {
  const { harvest, harvestResult, updateBox } = useHarvestContext();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  const { edit, dispose, addBoxToHarvest, reloadHarvest } = useHarvestInfo();

  if (harvest.id === null)
    return <PageWrapper title={'harvest.TITLE'} onClick={pushToBoard} loading={true} />;
  return (
    <PageWrapper
      title={'harvest.TITLE'}
      onClick={pushToBoard}
      loading={harvestResult.status === StatusType.loading}
    >
      <div className="row">
        <div className="col-12 col-xl-4 col-lg-6">
          <HarvestSummaryCard
            harvest={harvest}
            dispose={dispose}
            edit={edit}
            addBoxToHarvest={addBoxToHarvest}
            updateBox={updateBox}
            error={harvestResult as ServiceError}
          />
        </div>
        <div className="col-12 col-xl-8 col-lg-6">
          <BoxesInHarvestListCard
            disposed={harvest.allDisposedToWine}
            reloadHarvest={reloadHarvest}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <WinesInHarvestListCard />
        </div>
      </div>
      <div className="bg-gray-lighter rounded-lg">
        <HarvestHistory history={harvest?.history} />
      </div>
    </PageWrapper>
  );
};
