import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../hooks/usePagination';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { CompanyData, CompanyInfoData } from '../../types';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { CompanyRow } from './CompanyRow';
import PageWrapper from '../../../../layouts/PageWrapper';
import { useToggle } from '../../../../hooks/useToggle';
import InputElement from '../../../../components/common/InputElement';
import TextareaElement from '../../../../components/common/TextareaElement';
import { Button, CardBody } from 'reactstrap';

export const CompaniesTable: React.FC<{
  companies: CompanyInfoData[];
  loading: boolean;
  active: boolean;
  actions: {
    deactivateCompany: (id: number) => void;
    activateCompany: (id: number) => void;
    addNewCompany: (company: CompanyData) => void;
  };
}> = ({ companies, loading, active, actions }) => {
  const { t } = useTranslation();
  const pagination = usePagination(companies, 10);
  const [showForm, toggleShowForm] = useToggle(false);
  const [newCompany, setNewCompany] = useState<CompanyData>({ name: '', description: '' });
  const [submitting, setSubmitting] = useState(false);

  const { pushHistory } = usePushHistory();

  function navigateToCompany(id: number) {
    pushHistory(`/admin_console/company/${id}`);
  }

  function handleUpdate(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const name = e.target.name;
    const newValue = e.target.value;
    setNewCompany((prev) => ({ ...prev, [name]: newValue }));
  }

  function submitCompany() {
    setSubmitting(true);
    actions.addNewCompany(newCompany);
    setSubmitting(false);
  }

  return (
    <PageWrapper disabled loading={loading}>
      <div className="mb-3">
        <div>
          <button className="btn btn-sm btn-info" type="button" onClick={() => toggleShowForm()}>
            <em className={'mr-2 fas fa-' + (showForm ? 'minus' : 'plus')} /> winnicę
          </button>
        </div>
      </div>
      {showForm ? (
        <PageWrapper disabled loading={submitting}>
          <div className="card">
            <div className="card-body pb-1">
              <small className="text-muted float-right">
                {t('companies.add.form.table.title')}
              </small>
              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                <CardBody>
                  <InputElement
                    label={t('companies.add.form.table.name')}
                    name={'name'}
                    onChange={handleUpdate}
                  />

                  <TextareaElement
                    label={t('ingredients.SHORT_INFO')}
                    name={'description'}
                    defaultValue={newCompany.description}
                    maxSize={200}
                    onChange={handleUpdate}
                    optional
                    className={'pb-0 mb-0'}
                  />
                </CardBody>
                <div className="text-center mb-2">
                  <Button color="info" className="btn-square" onClick={submitCompany}>
                    {t('common.ADD')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      ) : null}
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('companies.table.title')}</small>
          {/*<div className="float-left">*/}
          {/*	<label>*/}
          {/*		/!*<em className="fas fa-search"/>*!/*/}
          {/*		<input*/}
          {/*			type="search"*/}
          {/*			className="form-control form-control-sm"*/}
          {/*			placeholder="Szukaj"*/}
          {/*		/>*/}
          {/*	</label>*/}
          {/*</div>*/}
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover table-sm">
                  <thead>
                    <tr role="row">
                      <th style={{ width: 50 }}>{t('companies.table.id')}</th>
                      <th style={{ width: 70 }}>{t('companies.table.active')}</th>
                      <th>{t('companies.table.name')}</th>
                      <th>{t('companies.table.subscriptionType')}</th>
                      <th>{t('companies.table.created')}</th>
                      <th>{t('companies.table.users')}</th>
                      <th>{t('companies.table.actions')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((company) => {
                      return (
                        <CompanyRow
                          company={company}
                          onClick={() => navigateToCompany(company.id)}
                          actions={actions}
                          active={active}
                          key={company.id}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </PageWrapper>
  );
};
