import React from 'react';
import { WineEntry } from '../../../wine/types/WineProduction';
import { Service, ServiceError } from '../../../../../../types/Service';
import { useGetWineEntry } from '../../hooks/useGetWineEntry';
import { useWineEntryOnClickService } from '../../hooks/useWineEntryOnClickService';
import useWineProductionService from '../../hooks/useWineProductionService';
import { useWineProductionContext } from '../../context/WineProductionContext';

export interface WineEntryToWineProductionProps {
  wineEntry?: WineEntry;
  actions: {
    onChange: () => void;
    updateSelect: () => void;
    updateSelectWineEntry: () => void;
    onSubmit: {
      update: (e: React.MouseEvent<HTMLButtonElement>) => void;
      save: (e: React.MouseEvent<HTMLButtonElement>) => void;
    };
    updateDate: () => void;
    key?: string;
  };
  error: ServiceError;
}

export interface WineEntryToWineProductionServiceProps<T> {
  addWineEntry: (wineEntry: WineEntry) => Promise<T>;
  editWineEntry: (wineEntryId: number, wineEntry: WineEntry) => Promise<T>;
  fieldName: 'wine' | 'wine_production';
  setResult: (value: Service<T>) => void;
}

export const withWineEntryToWineProductionServiceHOC =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const { wineEntry, error } = useGetWineEntry();
    const { setWineProductionResult } = useWineProductionContext();
    const { editWineEntry, fieldName } = props;
    const service = useWineProductionService();
    const actions = useWineEntryOnClickService(
      service.addWineEntry,
      editWineEntry,
      fieldName,
      setWineProductionResult
    );

    const newProps = {
      ...props,
      loading: actions.loading,
      wineEntry,
      error,
      actions
    };

    return <WrappedComponent {...newProps} />;
  };
