import React from 'react';

export const PaymentInfo: React.FC<{
  price: string;
  title: string;
}> = ({ price, title }) => {
  return (
    <div className={' px-2 pt-3'}>
      <p className={'text-muted'}>RACHUNEK DO RZELEWU</p>
      <span>Mariusz Bugara</span>
      <p>
        <span className={'text-muted'}>mBank S.A.</span> 90 1140 2004 0000 3002 7954 6535
      </p>
      <span>
        <span className={'text-muted'}>Kwota:</span> {price} zł.
      </span>
      <p>
        <span className={'text-muted'}>Tytułem:</span> {title}
      </p>
      <div className={'text-muted mt-4'}>
        W celu otrzymania faktury VAT z numerem NIP proszę przed dokonaniem płatności uzupełnić dane
        firmowe w zakładce Profil.
      </div>
    </div>
  );
};
