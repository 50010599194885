import React from 'react';
import { useTranslation } from 'react-i18next';
import { Ingredient } from '../../../winery/components/ingredient/types/Ingredient';
import usePagination from '../../../../hooks/usePagination';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { IngredientRow } from './IngredientRow';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';

export const IngredientsListTable: React.FC<{ ingredients: Ingredient[] }> = ({ ingredients }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const pagination = usePagination(ingredients, 10);

  const { pushHistory } = usePushHistory();

  function navigateToIngredient(id: number) {
    pushHistory(`/mv/ingredient/e/${id}`);
  }

  return (
    <div className="card">
      <div className="card-body pb-1">
        <small className="text-muted float-right">{t('winery.board.wines.table.title')}</small>
        {/*<div className="float-left">*/}
        {/*	<label>*/}
        {/*		/!*<em className="fas fa-search"/>*!/*/}
        {/*		<input*/}
        {/*			type="search"*/}
        {/*			className="form-control form-control-sm"*/}
        {/*			placeholder="Szukaj"*/}
        {/*		/>*/}
        {/*	</label>*/}
        {/*</div>*/}
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row">
            <div className="col-sm-12 table-responsive">
              <table className="table w-100 no-footer table-hover">
                <thead>
                  <tr role="row">
                    <th style={{ width: 85 }}>{t('ingredients.TYPE')}</th>
                    <th style={{ width: 200 }}>{t('ingredients.NAME')}</th>
                    {!isMobile ? <th style={{ width: 'auto' }}>{t('ingredients.INFO')}</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {pagination.getCurrentResults().map((ingredient) => {
                    return (
                      <IngredientRow
                        ingredient={ingredient}
                        onClick={() => navigateToIngredient(ingredient.id)}
                        key={ingredient.id}
                      />
                    );
                  })}
                  {pagination.getCurrentResults().length === 0 ? (
                    <tr>
                      <td colSpan={100} className="text-center">
                        Brak danych
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent pagination={pagination} />
    </div>
  );
};
