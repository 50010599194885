import React from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';
import { BoxWithGrapes } from '../../HarvestInfo/box/types/BoxWithGrapes';
import { BoxInHarvestRow } from './BoxInHarvestRow';

export const BoxesInHarvestListTable: React.FC<{
  boxes: BoxWithGrapes[];
  disposed: boolean;
  removeBox: (box: BoxWithGrapes) => void;
  reloadHarvest: () => void;
}> = ({ boxes, disposed, removeBox, reloadHarvest }) => {
  const { t } = useTranslation();
  const pagination = usePagination(boxes, 5);

  return (
    <div className="card">
      <div className="card-body pb-1">
        <small className="text-muted float-right">{t('harvest.box.table.title')}</small>
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row">
            <div className="col-sm-12 table-responsive">
              <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                <thead>
                  <tr role="row">
                    <th>{t('box.WEIGHT_OF_GRAPES_IN_BOX')}</th>
                    <th>{t('box.WEIGHT_OF_EMPTY_BOX')}</th>
                    <th>{t('box.WEIGHT_OF_FULL_BOX')}</th>
                    <th>{t('common.ACTIONS')}</th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.getCurrentResults().map((box) => {
                    return (
                      <BoxInHarvestRow
                        box={box}
                        disposed={disposed}
                        remove={removeBox}
                        reloadHarvest={reloadHarvest}
                        key={box.id}
                      />
                    );
                  })}
                  {pagination.getCurrentResults().length === 0 ? (
                    <tr>
                      <td colSpan={100} className="text-center">
                        Brak danych
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent pagination={pagination} />
    </div>
  );
};
