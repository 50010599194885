import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import useBoardService from '../../hooks/useBoardService';
import { WinesInTanksCard } from './wines/WinesInTanksCard';
import { WinerySummaryCard } from './WinerySummaryCard';
import { WinesListCard } from './wines/WinesListCard';
import { WinerySummaryData } from '../../../../types/dto';

export const WineryPage = () => {
  const serviceRef = useRef(useBoardService());
  const [summary, setSummary] = useState<WinerySummaryData>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getProduction()
        .then((response) => {
          setLoading(false);
          setSummary(response);
        })
        .catch(() => setLoading(false));
    }
  }, [loading, setSummary]);

  if (summary === null) return <PageWrapper title={'home.TITLE'} loading={loading} />;
  return (
    <PageWrapper title={'home.TITLE'} loading={loading}>
      <div className={'row'}>
        <div className="col-lg-12">
          {summary.winesInTanks.length > 0 ? (
            <WinesInTanksCard wines={summary.winesInTanks} />
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <WinerySummaryCard summary={summary} />
        </div>
        <div className="col-lg-8">
          <WinesListCard wines={summary.winesInSeason} />
        </div>
      </div>
    </PageWrapper>
  );
};
