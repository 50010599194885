import React, { useEffect, useState } from 'react';
import { Wine } from '../../../../wine/types/Wine';
import { WinesInHarvestListButtonsBar } from './WinesInHarvestListButtonsBar';
import { WinesInHarvestListTable } from './WinesInHarvestListTable';
import useWineService from '../../../../wine/hooks/useWineService';

export const WinesInHarvestListCard = () => {
  const wineService = useWineService();
  const [loadingWine, setLoadingWine] = useState(true);
  const [wines, setWines] = useState<Wine[]>([]);

  useEffect(() => {
    if (loadingWine) {
      wineService
        .getListByHarvest()
        .then((response) => {
          setWines(response);
        })
        .finally(() => setLoadingWine(false));
    }
  });

  return (
    <>
      <WinesInHarvestListButtonsBar reportAvailable={wines.length > 0} />
      <WinesInHarvestListTable wines={wines} loading={loadingWine} />
    </>
  );
};
