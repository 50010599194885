import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PdfPageLayout } from '../../../../types/enums';
import log from 'loglevel';

export const IngredientsListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({
  reportAvailable
}) => {
  const { t } = useTranslation();

  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/INGREDIENTS_SUMMARY?layout=${layout}`;
  }

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/ingredient/e/0',
            state: { from: '/mv/board/ingredients' }
          }}
          className="btn btn-sm btn-info"
          type="button"
        >
          <em className="mr-2 fas fa-plus" /> składnik
        </Link>
        <Link
          to={'/mv/ingredient/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        {reportAvailable ? (
          <button
            className="btn btn-sm btn-secondary ml-4 float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>
          </button>
        ) : null}
      </div>
    </div>
  );
};
