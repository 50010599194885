import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PlansPage } from './PlansPage';

export const PlansApp = () => {
  return (
    <Switch>
      <Route exact path="/mv/plans" component={PlansPage} />
    </Switch>
  );
};
