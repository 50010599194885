import { StatusType } from '../../../../../../../types/Service';
import { ResponseError } from '../../../../../../error/ResponseError';
import log from 'loglevel';
import { defaultError } from '../../../../parcel/context/ParcelContext';
import { useHarvestContext } from '../../../context/HarvestContext';
import { useEffect, useRef } from 'react';
import useHarvestService from '../../../hooks/useHarvestService';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../../../hooks/usePushHistory';

export function useHarvestInfo() {
  const { harvest, setHarvestResult, setError: setHarvestError } = useHarvestContext();
  const serviceRef = useRef(useHarvestService());
  const { harvestId } = useParams<RouterParams>();
  const { pushHistory } = usePushHistory();

  useEffect(() => {
    setHarvestResult({ status: StatusType.loading });
    harvestId &&
      serviceRef.current
        .get(parseInt(harvestId))
        .then((response) => {
          setHarvestResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setHarvestResult(new ResponseError(error)));
  }, [harvestId, setHarvestResult]);

  const edit = () => {
    const target = harvest.fromOtherVineyard ? 'grapes' : 'harvest';
    pushHistory(`/mv/${target}/e/${harvestId}/`);
  };
  const dispose = (e) => {
    e.preventDefault();
    setHarvestResult({ status: StatusType.loading });
    const action = harvest.allDisposedToWine
      ? serviceRef.current.revertDispose
      : serviceRef.current.dispose;
    harvest?.id &&
      action(harvest.id)
        .then((response) => {
          setHarvestResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setHarvestResult(new ResponseError(error)));
  };

  const addBoxToHarvest = (e) => {
    e.preventDefault();
    log.debug('HarvestInfo:addBoxToHarvest', e, harvest);
    setHarvestResult({ status: StatusType.loading });

    serviceRef.current
      .addBox(harvest.box || {})
      .then((response) => {
        setHarvestResult({ status: StatusType.loaded, payload: response });
        pushHistory(`/mv/harvest/info/${harvestId}`);
      })
      .catch((error) => {
        log.debug(error);
        setHarvestResult(new ResponseError(error));
      });
  };

  const reloadHarvest = () => {
    setHarvestError(defaultError);
  };

  return {
    edit,
    dispose,
    addBoxToHarvest,
    reloadHarvest
  };
}
