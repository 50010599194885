import React from 'react';
import { Link, useParams } from 'react-router-dom';
import log from 'loglevel';
import { PdfPageLayout } from '../../../../../../../types/enums';
import { RouterParams } from '../../../../../../../types/RouterParams';
import { useTranslation } from 'react-i18next';
import { useHarvestContext } from '../../../context/HarvestContext';

export const WinesInHarvestListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({
  reportAvailable
}) => {
  const { harvestId } = useParams<RouterParams>();
  const { harvest } = useHarvestContext();
  const { t } = useTranslation();
  function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    log.debug('GET_PDF');
    window.location.href = `/ajax/pdf/HARVEST_SUMMARY?layout=${layout}&id=${harvestId}`;
  }

  return (
    <div className="mb-3">
      <div>
        {harvest.allDisposedToWine ? (
          <button
            disabled={true}
            className="btn btn-sm btn-info"
            type="button"
            title={'Cofnij zamknięcie, aby dodać moszcz'}
          >
            <em className="mr-2 fas fa-plus" /> moszcz
          </button>
        ) : (
          <Link
            to={{
              pathname: `/mv/wine/info/0/${harvestId}`,
              state: { from: `/mv/harvest/info/${harvestId}` }
            }}
            className="btn btn-sm btn-info"
            type="button"
          >
            <em className="mr-2 fas fa-plus" /> moszcz
          </Link>
        )}
        <Link
          to={'/mv/wine/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        {reportAvailable ? (
          <button
            className="btn btn-sm btn-secondary ml-4 float-right"
            type="button"
            onClick={() => getPdf()}
            title={t('button.download.pdf')}
          >
            <em className="ml-auto fa fa-download fa-fw text-info"></em>
          </button>
        ) : null}
      </div>
    </div>
  );
};
