import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { CompanyData, CompanyInfoData } from '../../types';
import useAdminService from '../../hooks/useAdminService';
import { CompaniesTable } from './CompaniesTable';

export const CompaniesPage: React.FC<{ active: boolean }> = ({ active }) => {
  const [companies, setCompanies] = useState<CompanyInfoData[]>([]);
  const serviceRef = useRef(useAdminService());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (serviceRef.current != null) {
      console.log('getCompanyList');
      serviceRef.current
        .getCompanyList(active)
        .then((response) => {
          setCompanies(response);
        })
        .finally(() => setLoading(false));
    }
  }, [setCompanies, active]);

  function deactivateCompany(id: number) {
    setLoading(true);
    serviceRef.current
      .deactivateCompany(id)
      .then((response) => setCompanies((prev) => prev.filter((c) => c.id !== response.id)))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  function activateCompany(id: number) {
    setLoading(true);
    serviceRef.current
      .activateCompany(id)
      .then((response) => setCompanies((prev) => prev.filter((c) => c.id !== response.id)))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }

  function addNewCompany(company: CompanyData) {
    return serviceRef.current
      .addNewCompany(company)
      .then((response) => {
        const newState = [response, ...companies];
        setCompanies(newState);
      })
      .catch((error) => console.log(error));
  }

  return (
    <PageWrapper title={'admin.PAGE'} subtitle={'admin.COMPANIES'}>
      <CompaniesTable
        companies={companies}
        loading={loading}
        active={active}
        actions={{ deactivateCompany, activateCompany, addNewCompany }}
        key={companies.length}
      />
    </PageWrapper>
  );
};
