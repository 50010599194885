import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../../layouts/PageWrapper';
import { Button } from '../../../../../../../components/common/buttons/Button';
import CommonRow from '../../../../../../../components/common/table/CommonRow';
import usePagination from '../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';

const SimpleBottleEntryList = ({
  bottleEntries,
  loading,
  remove,
  info,
  children,
  removeDisabled
}) => {
  const { t } = useTranslation();
  const pagination = usePagination(bottleEntries || [], 10);

  function getThead() {
    return [`${t('bottleEntry.bottle.CAPACITY')}`, `${t('common.ACTIONS')}`];
  }

  const buildRow = (bottleEntry) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={2}>
        {bottleEntry?.quantity}
      </td>,
      <td style={{ textAlign: 'center' }} key={3}>
        {bottleEntry?.bottle.capacity}
      </td>,
      <td style={{ textAlign: 'right', minWidth: '110px' }} key={7}>
        {bottleEntry?.wineProduction ? (
          <Button.Info onClick={() => info(bottleEntry?.wineProduction)} />
        ) : null}
        <Button.Delete onClick={() => remove(bottleEntry)} disabled={removeDisabled} />
      </td>
    ];
    return <tr key={bottleEntry.id}>{fields}</tr>;
  };

  return (
    <PageWrapper loading={loading} disabled>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wineStorage.bottle.entry.ADDED')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {bottleEntries
              ?.map((entries) => entries.quantity)
              .reduce((total, item) => total + item, 0)}{' '}
            szt.
          </small>
          {pagination.getCurrentResults()?.length ? (
            <table className={'table w-100 no-footer table-hover table-sm'}>
              <tbody>
                <CommonRow
                  label={`${t('bottleEntry.QUANTITY')}`}
                  value={getThead()}
                  className={'text-center'}
                />
                {(pagination.getCurrentResults() || []).map((bottleEntry) => buildRow(bottleEntry))}
              </tbody>
            </table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {children}
          <PaginationComponent pagination={pagination} />
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

export default SimpleBottleEntryList;
