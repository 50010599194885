import React from 'react';
import ProgressBar from '../../../../components/common/charts/ProgressBar';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { WinerySummaryData } from '../../../../types/dto';

export const WinerySummaryCard: React.FC<{ summary: WinerySummaryData }> = ({ summary }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardBody>
        <ProgressBar
          percent={summary.totalPercentOfUsage}
          color={'info'}
          title={t('winery.board.summary.table.tanks.percent')}
        />
        <small className="text-muted float-right">{t('winery.board.summary.table.title')}</small>
        <Table>
          <tbody>
            <CommonRow
              label={t('winery.board.summary.table.tanks.total')}
              value={`${summary.totalTanksCapacity} l`}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('winery.board.summary.table.tanks.current')}
              value={`${summary.totalLitersInTanks} l`}
              valueColumnClassName={'text-right'}
            />
            {summary.totalWinesLitersInTanks ? (
              <CommonRow
                label={t('winery.board.summary.table.tanks.wines')}
                value={`${summary.totalWinesLitersInTanks} l`}
                valueColumnClassName={'text-right'}
              />
            ) : null}
            {summary.totalWhiteLitersInTanks ? (
              <CommonRow
                label={t('winery.board.summary.table.tanks.production.white')}
                value={`${summary.totalWhiteLitersInTanks} l`}
                valueColumnClassName={'text-right'}
              />
            ) : null}
            {summary.totalRedLitersInTanks ? (
              <CommonRow
                label={t('winery.board.summary.table.tanks.production.red')}
                value={`${summary.totalRedLitersInTanks} l`}
                valueColumnClassName={'text-right'}
              />
            ) : null}
            {summary.totalRoseLitersInTanks ? (
              <CommonRow
                label={t('winery.board.summary.table.tanks.production.rose')}
                value={`${summary.totalRoseLitersInTanks} l`}
                valueColumnClassName={'text-right'}
              />
            ) : null}
            {summary.totalOrangeLitersInTanks ? (
              <CommonRow
                label={t('winery.board.summary.table.tanks.production.orange')}
                value={`${summary.totalOrangeLitersInTanks} l`}
                valueColumnClassName={'text-right'}
              />
            ) : null}
            {summary.totalOtherLitersInTanks ? (
              <CommonRow
                label={t('winery.board.summary.table.tanks.production.other')}
                value={`${summary.totalOtherLitersInTanks} l`}
                valueColumnClassName={'text-right'}
              />
            ) : null}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
