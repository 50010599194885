import React, { useState } from 'react';

import { WineBoardPresentationData } from '../../../../../types/dto';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { WineColor } from '../../../../winery/components/wine/types/WineProduction';
import { useLongTouch } from '../../../../../hooks/useLongTouch';
import { FilesListItemColor } from '../../../../../types/FileType';
import { useTranslation } from 'react-i18next';
import useClickOutside from '../../../../../hooks/useClickOutside';

interface IProps {
  wine: WineBoardPresentationData;
}

export const WineInTankItem: React.FC<IProps> = ({ wine }) => {
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setShow(false);
  });

  function getColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return '';
    if (wine.wineColor === WineColor.ORANGE) return '';
    if (wine.wineColor === WineColor.RED) return 'pink';
    if (wine.wineColor === WineColor.ROSE) return 'pink';
    return 'gray';
  }

  function getBackgroundColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return 'rgba(255,207,81,0.49)';
    if (wine.wineColor === WineColor.ORANGE) return 'rgba(255,142,0,0.44)';
    if (wine.wineColor === WineColor.RED) return 'rgba(255,1,1,0.53)';
    if (wine.wineColor === WineColor.ROSE) return 'rgba(255,44,44,0.39)';
    return '';
  }

  function handleNavigateToEdit(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/${wine.entity}/e/${wine.id}`);
  }

  function handleNavigateToInfo(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/${wine.entity}/info/${wine.id}`);
  }

  function handleContextMenu(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    setShow(true);
  }

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useLongTouch(handleContextMenu);

  return (
    <>
      <div
        className="col-auto pt-2 pb-1 pl-3 pr-3"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onContextMenu={handleContextMenu}
        onClick={handleNavigateToInfo}
        style={{ cursor: 'pointer', width: '125px', userSelect: 'none', WebkitUserSelect: 'none' }}
      >
        <div
          className={`flex radial-bar radial-bar-info radial-bar-${
            Math.round(wine.tankPercentOfUsage / 5) * 5
          } radial-bar-md mb-1`}
          data-label={`${wine.tankPercentOfUsage}%`}
          style={{ fontSize: '12px' }}
        />
        <div>
          {
            <div
              className={`flex circle circle-lg bg-${getColor()}`}
              style={{ backgroundColor: getBackgroundColor() }}
            />
          }
          <p className={'m-0'} style={{ minWidth: '93px' }}>
            {wine.name}
          </p>
          {wine.wineTaste ? (
            <p className={'m-0 text-muted'}>
              <small>{wine.wineTaste ? t(`wine.TASTE.${wine.wineTaste}`) : null}</small>
            </p>
          ) : null}
        </div>
      </div>
      <div
        className={'dropdown-menu animated pulse ' + (show ? 'show' : '')}
        role="menu"
        style={{
          position: 'absolute',
          transform: 'translate3d(0px, 34px, 0px)',
          top: 100,
          left: 0,
          willChange: 'transform'
        }}
        ref={clickOutsideRef}
      >
        <div className="dropdown-item" onClick={handleNavigateToEdit} style={{ cursor: 'pointer' }}>
          Edytuj
        </div>
      </div>
    </>
  );
};
