import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import { Ingredient } from '../../../../../ingredient/types/Ingredient';
import PageWrapper from '../../../../../../../../layouts/PageWrapper';
import CardFooter from '../../../../../../../../components/common/cards/CardFooter';
import { FormErrorMessage } from '../../../../../../../../components/notifications/FormErrorMessage';
import { useIngredientContext } from '../../../../../ingredient/context/IngredientContext';
import { CommonListBox, ListBoxActions, ListBoxElementToShow } from './CommonListBox';

interface Props {
  ingredients: Ingredient[];
  ingredientToShow: ListBoxElementToShow;
  actions: Pick<ListBoxActions, 'toggleShow' | 'editElement' | 'removeElement'>;
  entityName: string;
  entityId?: number;
  disabledActions?: boolean;
  loading: boolean;
}

const IngredientsListBox: React.FC<Props> = ({
  ingredients,
  ingredientToShow,
  actions,
  entityName,
  entityId,
  disabledActions,
  loading
}) => {
  const { t } = useTranslation();
  const { error } = useIngredientContext();

  return (
    <PageWrapper loading={loading} disabled>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wine.info.ADDED_INGREDIENTS')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}: {ingredients?.length}
          </small>
          {ingredients?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('common.NAME')}:`}
                  value={[`${t('common.TYPE')}:`, `${t('common.QUANTITY')}:`]}
                />
                {(ingredients || []).map((i, key) => {
                  return (
                    <CommonListBox
                      header={[i.name || '', t(`ingredients.TYPE.${i.type}`), i.amount + ' g/hl']}
                      actions={actions}
                      disableActions={disabledActions}
                      elementToSHow={ingredientToShow}
                      path={`${entityName}/ingredient/${entityId}`}
                      elementId={i?.id}
                      dropdownInfo={{
                        paragraph: i.notes || t('ingredients.INFO.no_data')
                      }}
                      elementToShowKey={key}
                      key={key}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {error?.error?.details && (
            <CardFooter>
              <FormErrorMessage error={error} messageType={'details'} />
            </CardFooter>
          )}
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

export default IngredientsListBox;
