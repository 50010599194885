import React from 'react';
import { VarietiesListButtonsBar } from './VarietiesListButtonsBar';
import { VarietiesListTable } from './VarietiesListTable';
import { GrapevineSummaryPresentationData } from '../../../../../types/dto';

export const VarietiesListCard: React.FC<{ grapevines: GrapevineSummaryPresentationData[] }> = ({
  grapevines
}) => {
  return (
    <>
      <VarietiesListButtonsBar reportAvailable={grapevines.length > 0} />
      <VarietiesListTable grapevines={grapevines} />
    </>
  );
};
