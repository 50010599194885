import React from 'react';
import { WineInStorageItem } from './WineInStorageItem';
import { WineStoragePresentationData } from '../../../../types/dto';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import { useScroll } from '../../../../hooks/useScroll';

interface IProps {
  wines: WineStoragePresentationData[];
}

export const SelectedWines: React.FC<IProps> = ({ wines }) => {
  const { isMobile } = useResponsiveContext();
  const { scrollRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useScroll();

  return (
    <div>
      <div>
        <small className={'text-muted float-' + (isMobile ? 'right' : 'left')}>Ulubione wina</small>
      </div>
      <br />
      <div className="text-center position-relative">
        {!isMobile && canScrollLeft ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', opacity: '0.8' }}
            onClick={scrollLeft}
          >
            «
          </button>
        ) : null}
        <table
          className="table-responsive w-100 no-footer"
          ref={scrollRef}
          style={{ maxWidth: wines.length * 150 }}
        >
          <tbody>
            <tr className="d-flex flex-row">
              {wines.map((wine) => {
                return (
                  <td key={wine.id} className="d-flex flex-row pr-2" style={{ maxWidth: '150px' }}>
                    <div className="card mr-2 card-hovered">
                      <WineInStorageItem wine={wine} key={wine.id} />
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        {!isMobile && canScrollRight ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', right: '0', opacity: '0.8' }}
            onClick={scrollRight}
          >
            »
          </button>
        ) : null}
      </div>
    </div>
  );
};
