import React from 'react';
import { GrapevineSummaryPresentationData } from '../../../../../types/dto';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import { useScroll } from '../../../../../hooks/useScroll';
import { GrapevineItem } from './GrapevineItem';

interface IProps {
  grapevines: GrapevineSummaryPresentationData[];
  totalPlants: number;
  vineyardArea: number;
}

export const VarietiesCard: React.FC<IProps> = ({ grapevines, totalPlants, vineyardArea }) => {
  const { isMobile } = useResponsiveContext();
  const { scrollRef, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useScroll();

  return (
    <div>
      <div>
        <small className={'text-muted float-' + (isMobile ? 'right' : 'left')}>
          Uprawiane odmiany winorośli
        </small>
      </div>
      <br />
      <div className="text-center">
        {!isMobile && canScrollLeft ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', opacity: '0.8' }}
            onClick={scrollLeft}
          >
            «
          </button>
        ) : null}
        <table
          className="table-responsive w-100 no-footer"
          ref={scrollRef}
          style={{ maxWidth: grapevines.length * 150 }}
        >
          <tbody>
            <tr className="d-flex flex-row">
              {grapevines.map((grapevine) => {
                return (
                  <td
                    key={grapevine.id}
                    className="d-flex flex-row pr-2"
                    style={{ maxWidth: '150px' }}
                  >
                    <div className="card mr-2 card-hovered">
                      <GrapevineItem
                        grapevine={grapevine}
                        totalPlants={totalPlants}
                        vineyardArea={vineyardArea}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        {!isMobile && canScrollRight ? (
          <button
            className="page-link position-absolute"
            style={{ zIndex: '1000', top: '35%', right: '0', opacity: '0.8' }}
            onClick={scrollRight}
          >
            »
          </button>
        ) : null}
      </div>
    </div>
  );
};
