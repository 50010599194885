import React from 'react';
import { WineStoragePresentationData } from '../../../../types/dto';
import { StorageWinesListButtonsBar } from './StorageWinesListButtonsBar';
import { StorageWinesListTable } from './StorageWinesListTable';

export const StorageListCard: React.FC<{ wines: WineStoragePresentationData[] }> = ({ wines }) => {
  return (
    <>
      <StorageWinesListButtonsBar reportAvailable={wines.length > 0} />
      <StorageWinesListTable wines={wines} />
    </>
  );
};
