import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { VarietiesCard } from './varieties/VarietiesCard';
import { VineyardSummaryCard } from './VineyardSummaryCard';
import { VarietiesListCard } from './varieties/VarietiesListCard';
import useBoardService from '../../hooks/useBoardService';
import { VineyardSummaryData } from '../../../../types/dto';
import { HarvestsListCard } from './harvests/HarvestsListCard';

export const VineyardSummaryPage = () => {
  const serviceRef = useRef(useBoardService());
  const [summary, setSummary] = useState<VineyardSummaryData>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getSummary()
        .then((response) => {
          setSummary(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, setSummary]);
  if (summary === null) return <PageWrapper title={'home.summary.TITLE'} loading={loading} />;
  return (
    <PageWrapper title={'home.summary.TITLE'} loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          {summary.grapevines.length > 0 ? (
            <VarietiesCard
              grapevines={summary.grapevines}
              totalPlants={summary.totalPlants}
              vineyardArea={summary.vineyardArea}
            />
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <VineyardSummaryCard summary={summary} />
        </div>
        <div className="col-lg-8">
          <VarietiesListCard grapevines={summary.grapevines} />
          <HarvestsListCard harvests={summary.harvests} />
        </div>
      </div>
    </PageWrapper>
  );
};
