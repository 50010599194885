import React, { ChangeEvent, FC } from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import InputElement from '../../../../../../components/common/InputElement';
import { ServiceError } from '../../../../../../types/Service';
import { Harvest } from '../../types/Harvest';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { Checkbox } from '../../../../../../components/common/form-elements/Checkbox';
import SelectGrapeColor from '../../../../common/select/SelectGrapeColor';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  error: ServiceError;
  harvest: Harvest;
  updateHarvest: (e: ChangeEvent<HTMLInputElement>) => void;
  updateDate: (date: Date | string) => void;
  loading: boolean;
  onClickBack: () => void;
  updateHarvestCheckbox: (name: string, value: boolean) => void;
  handleUpdateSelect: () => void;
}

const SimpleGrapesForm: FC<Props> = ({
  onSubmit,
  error,
  harvest,
  updateHarvest,
  updateDate,
  loading,
  onClickBack,
  updateHarvestCheckbox,
  handleUpdateSelect
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  return (
    <PageWrapper
      title={'harvest.TITLE'}
      subtitle={'harvest.LIST'}
      onClick={pushToBoard}
      loading={loading}
    >
      <Card>
        <CardBody>
          <div className={'pb-5'}>
            <h5 className="float-right">
              {harvest?.id
                ? t('sidebar.nav.element.EDIT')
                : t('sidebar.nav.element.ADD_GRAPES.TITLE')}
            </h5>
          </div>
          <InputElement
            label={t('grapes.NAME')}
            name={'varietyName'}
            type={'text'}
            defaultValue={harvest?.varietyName}
            onChange={updateHarvest}
            error={error}
            disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <SelectGrapeColor
            value={harvest?.grapeColor}
            name={'grapeColor'}
            label={t('grapevine.GRAPE_COLOR')}
            onChange={handleUpdateSelect}
            error={error}
            disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('harvest.WEIGHT_OF_GRAPES')}
            name={'weightOfGrapes'}
            type={'number'}
            defaultValue={harvest?.weightOfGrapes}
            onChange={updateHarvest}
            error={error}
            disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputDate
            label={t('grapes.DATE_OF_HARVEST')}
            name={'dateOfHarvest'}
            onChange={updateDate}
            defaultValue={harvest?.dateOfHarvest}
            error={error}
            disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <InputElement
            label={t('grapes.INFO')}
            name={'info'}
            type={'textarea'}
            defaultValue={harvest?.info}
            onChange={updateHarvest}
            error={error}
            optional
            disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
          />
          <Checkbox
            name={'fromOtherCountry'}
            label={t('grapes.FROM_OTHER_COUNTRY')}
            value={!!harvest.fromOtherCountry}
            onChange={() => updateHarvestCheckbox('fromOtherCountry', !harvest.fromOtherCountry)}
          />
          <FormErrorMessage error={error} messageType={'details'} />
          <div className="text-center bg-secondary">
            {harvest?.liveStatus === EntityLiveStatus.ARCHIVED ? (
              <Button color="info" className="btn-square" onClick={onClickBack}>
                {t('common.BACK')}
              </Button>
            ) : (
              <Button color="info" className="btn-square" onClick={onSubmit}>
                {harvest?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            )}
          </div>
        </CardBody>
      </Card>
    </PageWrapper>
  );
};

export default SimpleGrapesForm;
