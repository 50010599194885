import React from 'react';
import ProgressBar from '../../../../components/common/charts/ProgressBar';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { VineyardSummaryData } from '../../../../types/dto';
import { useTranslation } from 'react-i18next';

export const VineyardSummaryCard: React.FC<{ summary: VineyardSummaryData }> = ({ summary }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <ProgressBar
          percent={Math.floor((summary.vineyardArea / summary.totalArea) * 100)}
          color={'green'}
          title={t('vineyard.board.summary.table.area.percent')}
        />
        <small className="text-muted float-right">{t('vineyard.board.summary.table.title')}</small>
        <Table>
          <tbody>
            <CommonRow
              label={t('vineyard.board.summary.table.area.total')}
              value={`${summary.totalArea} ha`}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.area.vineyard')}
              value={`${summary.vineyardArea} ha`}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.area.white')}
              value={`${summary.whiteVarietyArea} ha`}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.area.red')}
              value={`${summary.redVarietyArea} ha`}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.varieties')}
              value={summary.totalVarieties}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.plants')}
              value={summary.totalPlants}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.first')}
              value={summary.firstPlant}
              valueColumnClassName={'text-right'}
            />
            <CommonRow
              label={t('vineyard.board.summary.table.last')}
              value={summary.lastPlant}
              valueColumnClassName={'text-right'}
            />
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
