import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext } from '../../../platform/AuthContext';
import TankProvider from './context/TankContext';
import TankList from './components/TankList';
import { TankForm } from './components/TankForm';

const TankApp = () => {
  const { principal } = useContext(AuthContext);
  return (
    <TankProvider>
      <Switch>
        <Route exact path="/mv/tank">
          <Redirect to="/mv/tank/all" />
        </Route>
        <Route exact path="/mv/tank/all" component={TankList} />
        <Route exact path="/mv/tank/e/:tankId">
          <TankForm principal={principal} />
        </Route>
      </Switch>
    </TankProvider>
  );
};

export default TankApp;
