import React from 'react';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { HarvestPresentation } from '../../../../winery/components/harvest/types/Harvest';
import { SummaryHarvestRow } from './SummaryHarvestRow';
import { PaginationComponent } from '../../../../../components/PaginationComponent';
import usePagination from '../../../../../hooks/usePagination';
import { useTranslation } from 'react-i18next';

export const HarvestsListTable: React.FC<{ harvests: HarvestPresentation[]; title: string }> = ({
  harvests,
  title
}) => {
  const { t } = useTranslation();
  const pagination = usePagination(harvests, 10);

  const { pushHistory } = usePushHistory();

  function navigateToHarvest(id: number) {
    pushHistory('/mv/harvest/info/' + id);
  }

  return (
    <div className="card">
      <div className="card-body pb-1">
        <small className="text-muted float-right">{title}</small>
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row">
            <div className="col-sm-12 table-responsive">
              <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                <thead>
                  <tr role="row">
                    <th style={{ width: 85 }}>{t('vineyard.board.harvest.table.grapevine')}</th>
                    <th style={{ width: 215 }}>{t('vineyard.board.harvest.table.date')}</th>
                    <th style={{ width: 135 }}>{t('vineyard.board.harvest.table.grapes')}</th>
                    <th style={{ width: 165 }}>{t('vineyard.board.harvest.table.must')}</th>
                    <th style={{ width: 135 }}>{t('vineyard.board.harvest.table.waste')}</th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.getCurrentResults().map((harvest) => {
                    return (
                      <SummaryHarvestRow
                        grapevineName={harvest.grapevineName || harvest.varietyName}
                        dateOfHarvest={harvest.dateOfHarvest.toString()}
                        weightOfGrapes={harvest.weightOfGrapes}
                        amountOfMust={harvest.amountOfMust}
                        amountOfWaste={harvest.amountOfWaste}
                        onClick={() => navigateToHarvest(harvest.id)}
                        key={harvest.id}
                      />
                    );
                  })}
                  {pagination.getCurrentResults().length === 0 ? (
                    <tr>
                      <td colSpan={100} className="text-center">
                        Brak danych
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <PaginationComponent pagination={pagination} />
    </div>
  );
};
