import { useEffect, useState } from 'react';
import { defaultError } from '../../components/parcel/context/ParcelContext';
import { EntityLiveStatus } from '../../../../components/common/enums/EntityLiveStatus';
import { useParams } from 'react-router-dom';
import { Service, ServiceError, StatusType } from '../../../../types/Service';
import { ResponseError } from '../../../error/ResponseError';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../notifications/useConfirmation';

export interface TPagination<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: TPageable;
  size: number;
  sort: TSort;
  totalElements: number;
  totalPages: number;
}

interface TPageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: TSort;
  unpaged: boolean;
}

interface TSort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface TService<T> {
  getAll: (page: number, status?: string) => Promise<TPagination<T>>;
  del: (id: number) => Promise<T>;
  archive: (id: number) => Promise<T>;
  revertArchive: (id: number) => Promise<T>;
}

interface Setup<T, RETURN_TYPE> {
  service: TService<T>;
  setError: (value: ServiceError) => void;
  newPage?: number;
  setResult?: (value: Service<RETURN_TYPE>) => void;
  triggerReload?: boolean;
}

export const useFetchEntityPage = <T, RETURN_TYPE>({
  service,
  setError,
  newPage,
  setResult,
  triggerReload
}: Setup<T, RETURN_TYPE>) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(newPage || 0);
  const [pagination, setPagination] = useState<Partial<TPagination<T>>>({});
  const { status } = useParams<{ status?: string }>();
  const [isErrorResponse, setIsErrorResponse] = useState(false);
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();

  const reload = () => {
    setPagination({});
    setError(defaultError);
  };

  useEffect(() => {
    triggerReload && reload();
  }, [triggerReload]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((pagination?.content && pagination.number === page) || isErrorResponse || loading) {
      return;
    }
    setLoading(true);
    service
      .getAll(page, status || EntityLiveStatus.CREATED)
      .then((p) => {
        setPagination(p);
        setLoading(false);
        setError(defaultError);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        setIsErrorResponse(true);
      });
  }, [
    pagination,
    setPagination,
    setLoading,
    service,
    setError,
    page,
    status,
    isErrorResponse,
    setIsErrorResponse,
    loading
  ]);

  const changePage = (e) => {
    e.preventDefault();
    setPage(e.target.name - 1);
  };

  const onNext = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };

  const onPrev = (e) => {
    e.preventDefault();
    setPage(page - 1);
  };

  const paginationActions = {
    changePage: changePage,
    onPrev: onPrev,
    onNext: onNext
  };

  const recalculatePageAfterRemoveOrArchiveActions = () => {
    if (pagination.numberOfElements === 1 && page > 0) {
      setPage(page - 1);
    }
  };

  const removeEntity = (entity) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(entity.id)
    });
  };

  const handleRemove = (id: number) => {
    setLoading(true);
    setResult?.({ status: StatusType.loading });
    recalculatePageAfterRemoveOrArchiveActions();
    service
      .del(id)
      .then((response) => {
        reload();
        setLoading(false);
        setResult?.({ status: StatusType.loaded, payload: response as unknown as RETURN_TYPE });
      })
      .catch((error) => {
        setError(error);
        setResult?.(new ResponseError(error));
        setLoading(false);
      });
  };

  const archiveEntity = (entity) => {
    recalculatePageAfterRemoveOrArchiveActions();
    service
      .archive(entity.id)
      .then(() => {
        reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const revertArchiveEntity = (entity) => {
    recalculatePageAfterRemoveOrArchiveActions();
    service
      .revertArchive(entity.id)
      .then(() => {
        reload();
      })
      .catch((error) => setError(error));
  };

  return {
    pagination,
    pageResult: pagination?.content,
    loading,
    removeEntity,
    archiveEntity,
    revertArchiveEntity,
    paginationActions,
    currentPage: page
  };
};
