import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useWineContext } from '../../context/WineContext';
import useWineService from '../../hooks/useWineService';
import { StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { useTranslation } from 'react-i18next';
import { RouterParams } from '../../../../../../types/RouterParams';
import { useConfirmation } from '../../../../../notifications/useConfirmation';

export interface Button {
  action: () => void;
  label: string;
}

export interface WineInfoButtons {
  saveEditButton: Button;
  archiveButton?: Button;
  toProductionButton?: Button;
}

const WineInfoContainer = ({ render }) => {
  const { updateWine, setWineResult, wine, setError } = useWineContext();
  const service = useWineService();
  const { wineId } = useParams<RouterParams>();
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();

  const toProduction = () => {
    showConfirmation({
      message: t('alerts.confirmation.TO_PRODUCTION'),
      actionLabel: 'Tak',
      actionButtonColor: 'warning',
      actionCallback: () => handleToProduction()
    });
  };

  useEffect(() => {
    setWineResult({ status: StatusType.loading });
    wineId &&
      service
        .get(parseInt(wineId))
        .then((response) => {
          setWineResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setWineResult(new ResponseError(error)));

    return () => updateWine('reset', '');
  }, [wineId]); // eslint-disable-line react-hooks/exhaustive-deps

  const editWine = () => {
    pushHistory(`/mv/wine/e/${wineId}/`);
  };

  const archive = () => {
    const action =
      wine?.liveStatus === EntityLiveStatus.ARCHIVED ? service.revertArchive : service.archive;
    setWineResult({ status: StatusType.loading });
    action(wine?.id)
      .then((response) => {
        setWineResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineResult({ status: StatusType.error });
      });
  };

  const handleToProduction = () => {
    setWineResult({ status: StatusType.loading });
    service
      .toProduction(wine?.id)
      .then((wineProductionId) => {
        pushHistory(`/mv/wine_production/info/${wineProductionId}/`);
      })
      .catch((error) => {
        setError(error);
        setWineResult({ status: StatusType.error });
      });
  };

  const saveEditButton: Button = {
    action: editWine,
    label: wine?.liveStatus === EntityLiveStatus.ARCHIVED ? t('button.VIEW') : t('button.EDIT')
  };

  const toProductionButton: Button = {
    action: toProduction,
    label: t('button.toProduction')
  };

  const archiveButton: Button = {
    action: archive,
    label:
      wine?.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE')
  };

  const buttons: WineInfoButtons = {
    saveEditButton,
    archiveButton,
    toProductionButton
  };

  return render(buttons);
};

export default WineInfoContainer;
