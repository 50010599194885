import React from 'react';
import { Subscription, SubscriptionStatus, SubscriptionType } from '../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from './PaymentInfo';
import { Plans } from '../../../plans/data/Plans';
import { useAuthContext } from '../../../platform/AuthContext';

export const ActivatedPlanInfo: React.FC<{
  navigateToPlans: () => void;
  subscription: Subscription;
  inProgressSubscription?: Subscription;
  color: string;
}> = ({ navigateToPlans, subscription, inProgressSubscription, color }) => {
  const { t } = useTranslation();
  const { principal } = useAuthContext();
  const chosenPlan = Plans.find(
    (plan) => plan.type === inProgressSubscription?.type && plan.status !== SubscriptionStatus.TRIAL
  );

  const actualPlan = Plans.find(
    (plan) => plan.type === subscription?.type && plan.status !== SubscriptionStatus.TRIAL
  );
  return (
    <div>
      {inProgressSubscription && inProgressSubscription.type !== subscription.type ? (
        <div className={'pb-2'}>
          Po zaksięgowaniu wpłaty twój nowy plan{' '}
          <span className={color}>
            {t(`plans.subscription.type.${inProgressSubscription.type}`)}
          </span>{' '}
          zostanie aktywowany.
        </div>
      ) : null}

      {subscription.type !== SubscriptionType.BASIC ? (
        <div className={'pb-2'}>
          Twój plan pozostanie aktywny do:{' '}
          <span className={'text-success'}>{subscription.validToDate}</span>
        </div>
      ) : null}

      {inProgressSubscription && inProgressSubscription.type !== subscription.type ? (
        <div className={'pb-3'}>
          <PaymentInfo
            price={Math.floor(chosenPlan.amount - (actualPlan.amount || 0))?.toString()}
            title={principal.login + ' - ' + inProgressSubscription.type}
          />
        </div>
      ) : null}

      {subscription.type === SubscriptionType.PREMIUM ? (
        <div>
          Miło widzieć Cię w gronie <strong>PREMIUM</strong> :)
        </div>
      ) : null}
      {subscription.type !== SubscriptionType.PREMIUM &&
      inProgressSubscription?.type !== SubscriptionType.PREMIUM ? (
        <div>
          Aby korzystać z większej ilości funkcji w każdej chwili możesz{' '}
          <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
            zwiększyć plan.
          </span>
        </div>
      ) : null}
      {inProgressSubscription?.type === SubscriptionType.PREMIUM ? (
        <div>
          Możesz jeszcze{' '}
          <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
            zmienić plan.
          </span>
        </div>
      ) : null}
    </div>
  );
};
