import { Ingredient } from '../../ingredient/types/Ingredient';
import { Tank } from '../../tank/types/Tank';
import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';
import { ProductionEvent, Wine, WineStatus } from './Wine';
import { History } from './History';
import { WineInfo } from '../../../common/WineInfoBox';

export enum WineColor {
  WHITE = 'WHITE',
  RED = 'RED',
  ROSE = 'ROSE',
  ORANGE = 'ORANGE',
  OTHER = 'OTHER'
}

export enum WineTaste {
  DRY = 'DRY',
  SEMIDRY = 'SEMIDRY',
  SEMISWEET = 'SEMISWEET',
  SWEET = 'SWEET'
}

export interface WineProduction extends SimplyWineProduction {
  id?: number;
  tank: Tank;
  liters: number;
  wineEntries: WineEntry[];
  disposedAsWineEntry: WineEntry[];
  wineEntriesInfo: WineInfo[];
  ingredients: Ingredient[];
  history: History[];
  events: ProductionEvent[];
  lastIngredient: Ingredient;
  lastEvent: ProductionEvent;
  liveStatus: EntityLiveStatus;
  startDate: string;
  varietalWine: boolean;
  protectedNameOrGeoWine: boolean;
}

export interface WineProductionPresentation {
  id: number;
  name: string;
  wineColor: WineColor;
  wineTaste: WineTaste;
  tankNumber: string;
  tankCapacity: number;
  liters: number;
  liveStatus: EntityLiveStatus;
}

export interface SimplyWineProduction {
  name: string;
  wineColor: WineColor;
  wineTaste: WineTaste;
  finished: boolean;
  status: WineStatus;
}

export interface WineEntry {
  id: number;
  name: string;
  wine: Wine;
  wineProduction?: WineProduction;
  wineProductionConsumer: WineProduction;
  tank: Tank;
  liters: number;
  created: Date;
  historyEdgeDate: Date;
  addedToProduction: Date;
  entity: 'wine' | 'wine_production';
  entryType: WineEntryType;
  entryId: number;
}

export enum WineEntryType {
  WINE = 'WINE',
  WINE_PRODUCTION = 'WINE_PRODUCTION',
  WINE_STORAGE = 'WINE_STORAGE'
}

export enum WineEntryTypeForSelect {
  WINE = 'WINE',
  WINE_PRODUCTION = 'WINE_PRODUCTION'
}
