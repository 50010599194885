import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';
import { WineColor, WineProduction, WineTaste } from '../../wine/types/WineProduction';
import { Tank } from '../../tank/types/Tank';

export interface WineStorage extends SimplyWineStorageData {
  id: number;
  bottleEntries: BottleEntry[];
  tankEntries: TankEntry[];
  bottleOutputs: BottleOutput[];
  tankOutputs: TankOutput[];
  liveStatus: EntityLiveStatus;
  totalLiters: number;
  totalBottles: number;
  litersInBottles: number;
  totalTanks: number;
  litersInTanks: number;
  maxLiters: number;
  maxBottles: number;
  maxLitersInBottles: number;
  maxTanks: number;
  maxLitersInTanks: number;
  taxBandsPlacements: TaxBandsPlacement[];
  taxBandsTotalQuantity: number;
  taxBandsActualQuantity: number;
  varietalWine: boolean;
  favoriteWine: boolean;
  protectedNameOrGeoWine: boolean;
  fromUE: boolean;
  outsideUE: boolean;
  totalNotFinishedPlacementsOfTaxBands: number;
  taxBandsPlacementNeedAttention: boolean;
}

export interface TaxBandsPlacement {
  id: number;
  quantity: number;
  placementDate: Date;
  declarationDate: Date;
  payed: boolean;
  needAttention: boolean;
  wineStorageId: number;
}

export interface SimplyWineStorageData {
  name: string;
  wineColor: WineColor;
  wineTaste: WineTaste;
}

export interface BottleEntry {
  id: number;
  bottle: Bottle;
  quantity: number;
  wineProduction: Partial<WineProduction>;
}

export type BottleOutput = {
  id: number;
  bottle: Bottle;
  quantity: number;
  hasTaxBands: boolean;
  outputDate: Date;
  outputType: BottleOutputType;
};

export type TankOutput = {
  id: number;
  liters: number;
  outputDate: Date;
  outputType: TankOutputType;
  bottleEntry: BottleEntry;
  tank: Tank;
};

export enum BottleOutputType {
  SALE = 'SALE',
  TASTING = 'TASTING',
  WASTE = 'WASTE',
  OWN_CONSUMPTION = 'OWN_CONSUMPTION',
  OTHER = 'OTHER'
}

export enum TankOutputType {
  SALE = 'SALE',
  TASTING = 'TASTING',
  WASTE = 'WASTE',
  OWN_CONSUMPTION = 'OWN_CONSUMPTION',
  BOTTLING = 'BOTTLING',
  OTHER = 'OTHER'
}

export interface TankEntry {
  id: number;
  tank: Tank;
  liters: number;
  actualLiters: number;
  wineProduction: Partial<WineProduction>;
}

export interface Bottle {
  id: number;
  name: string;
  capacity: number;
  info: string;
}

export enum WineStorageEntryType {
  BOTTLE = 'BOTTLE',
  TANK = 'TANK'
}
