import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../../layouts/PageWrapper';
import { Button } from '../../../../../../../components/common/buttons/Button';
import CommonRow from '../../../../../../../components/common/table/CommonRow';
import usePagination from '../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../components/PaginationComponent';

export const SimpleBottleOutputList = ({
  bottleOutputs,
  loading,
  removeOutput,
  children,
  title,
  removeDisabled
}) => {
  const { t } = useTranslation();
  const pagination = usePagination(bottleOutputs || [], 10);

  function getThead() {
    return [`${t('bottleOutput.TYPE')}`, `${t('bottleOutput.date')}`, `${t('common.ACTIONS')}`];
  }

  const buildRow = (bottleOutput) => {
    const fields = [
      <td style={{ textAlign: 'center' }} key={2}>
        {bottleOutput?.quantity}{' '}
        <sup className={'text-muted'}>{`(${bottleOutput?.bottle.capacity} l.)`}</sup>
      </td>,
      <td style={{ textAlign: 'center' }} key={4}>
        {t(`wine.OUTPUT.TYPE.${bottleOutput?.outputType}`)}
      </td>,
      <td style={{ textAlign: 'center' }} key={5}>
        {bottleOutput?.outputDate}
      </td>,
      <td style={{ textAlign: 'right', width: '60px' }} key={7}>
        <Button.Delete onClick={() => removeOutput(bottleOutput)} disabled={removeDisabled} />
      </td>
    ];
    return <tr key={bottleOutput.id}>{fields}</tr>;
  };

  return (
    <PageWrapper loading={loading} disabled>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{title}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {bottleOutputs
              ?.map((output) => output.quantity)
              .reduce((total, item) => total + item, 0)}{' '}
            szt.
          </small>
          {pagination.getCurrentResults()?.length ? (
            <div className={'table-responsive'}>
              <table className={'table w-100 no-footer table-hover table-sm'}>
                <tbody>
                  <CommonRow
                    label={`${t('bottleEntry.QUANTITY')}`}
                    value={getThead()}
                    className={'text-center'}
                  />
                  {(pagination.getCurrentResults() || []).map((bottleEntry) =>
                    buildRow(bottleEntry)
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {children}
          <div className={'float-right pt-2'} style={{ marginBottom: '-20px' }}>
            <PaginationComponent pagination={pagination} />
          </div>
        </CardBody>
      </Card>
    </PageWrapper>
  );
};
