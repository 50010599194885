import React from 'react';
import { Link } from 'react-router-dom';

const Error500 = () => {
  const year = new Date().getFullYear();

  return (
    <div className="wrapper">
      <div className="abs-center wd-xl">
        {/* START card*/}
        <div className="text-center mb-4">
          <div className="mb-3">
            <em className="fa fa-wrench fa-5x text-muted" />
          </div>
          <div className="text-lg mb-3">500</div>
          <p className="lead m-0">Oj! Coś poszło nie tak :(</p>
          <p>Nie martw się, właśnie to sprawdzamy.</p>
          <p>W międzyczasie możesz zajrzeć do jednego z linków poniżej</p>
        </div>
        <ul className="list-inline text-center text-sm mb-4">
          <li className="list-inline-item">
            <a className="text-muted" href="login.html">
              <Link to="/" className="text-primary">
                Aplikacja
              </Link>
            </a>
          </li>
          <li className="text-muted list-inline-item">|</li>
          <li className="list-inline-item">
            <a className="text-muted" href="login.html">
              <Link to="/welcome/login" className="text-primary">
                Zaloguj
              </Link>
            </a>
          </li>
        </ul>
        <div className="p-3 text-center">
          <span className="mr-2">©</span>
          <span>{`${year} - WinApp`}</span>
          <br />
          <span>Aplikacja do zarządzania produkcją wina</span>
        </div>
      </div>
    </div>
  );
};

export default Error500;
