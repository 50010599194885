import React from 'react';

export type PlanItemType = {
  title: string;
  include: boolean;
};

export const PlanItem: React.FC<PlanItemType> = ({ title, include }) => {
  return (
    <p>
      {include ? (
        <em className="fa fa-check fa-fw text-green mr-2" />
      ) : (
        <em className="fa fa-times fa-fw text-warning mr-2" />
      )}
      <span>{title}</span>
    </p>
  );
};
