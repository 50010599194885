import React from 'react';
import { WinesListTable } from './WinesListTable';
import { WineBoardPresentationData } from '../../../../../types/dto';
import { WinesListButtonsBar } from './WinesListButtonsBar';

export const WinesListCard: React.FC<{
  wines: WineBoardPresentationData[];
  hideButtons?: boolean;
}> = ({ wines, hideButtons }) => {
  return (
    <>
      {!hideButtons ? <WinesListButtonsBar reportAvailable={wines.length > 0} /> : null}
      <WinesListTable wines={wines} />
    </>
  );
};
