import React, { FC } from 'react';
import { Card, CardBody, CardFooter, Table } from 'reactstrap';
import ProgressBar from '../../../components/common/charts/ProgressBar';
import SmallTextBadge from '../../../components/common/badges/SmallTextBadge';
import CommonRow from '../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../layouts/PageWrapper';
import { WineInfoButtons } from '../components/wine/components/WineInfo/WineInfoContainer';
import { ProductionEventForWineType, Wine } from '../components/wine/types/Wine';
import { WineProduction } from '../components/wine/types/WineProduction';
import { EntityLiveStatus } from '../../../components/common/enums/EntityLiveStatus';
import SelectTank from '../components/wine/components/WineForm/SelectTank';
import { ServiceError } from '../../../types/Service';
import { FormErrorMessage } from '../../../components/notifications/FormErrorMessage';

export type WineInfo = Pick<
  Wine,
  | 'id'
  | 'name'
  | 'status'
  | 'tank'
  | 'liters'
  | 'startDate'
  | 'lastIngredient'
  | 'lastEvent'
  | 'liveStatus'
  | 'harvest'
> & {
  entity: 'wine' | 'wine_production';
  addedToProduction?: Date;
  tankPercentOfUsage?: number;
  tankNumber?: number;
} & Pick<WineProduction, 'wineColor' | 'wineTaste'>;

const WineInfoBox: FC<{
  buttons: WineInfoButtons;
  wine: Partial<WineInfo>;
  loading: boolean;
  updateTankInWineProduction?: () => void;
  error?: ServiceError;
  isBoardPage?: boolean;
}> = ({
  buttons: { saveEditButton, archiveButton, toProductionButton },
  wine,
  loading,
  updateTankInWineProduction,
  error,
  isBoardPage = false
}) => {
  const { t } = useTranslation();

  const lastEventName =
    wine?.lastEvent?.type === ProductionEventForWineType.OTHER && wine?.lastEvent?.name
      ? wine?.lastEvent?.name
      : t(`event.type.${wine?.lastEvent?.type}`) + '';

  return (
    <PageWrapper loading={loading} disabled>
      <Card>
        <CardBody>
          {wine?.wineTaste && (
            <SmallTextBadge
              title={t(`wine.TASTE.${wine.wineTaste}`)}
              color={t(`wine_info_box.badge.color.${wine.wineTaste}`)}
            />
          )}
          {wine?.wineColor && (
            <SmallTextBadge
              title={t(`wine.COLOR.${wine.wineColor}`)}
              color={t(`wine_info_box.badge.color.${wine.wineColor}`)}
            />
          )}
          <SmallTextBadge
            title={t(`wine_info_box.badge.text.${wine.entity}`)}
            color={t(`wine_info_box.badge.color.${wine.entity}`)}
          />
          <h4 className="m-0">{wine?.name}</h4>
          {wine?.tank?.number || wine?.tankNumber ? (
            <small className="text-muted">
              Zbiornik nr {wine?.tank?.number || wine.tankNumber}
            </small>
          ) : wine.liveStatus !== EntityLiveStatus.ARCHIVED && wine?.liters > 0 ? (
            <small className="text-danger">Umieść wino w zbiorniku</small>
          ) : null}
          {wine?.tank?.number || wine?.tankPercentOfUsage ? (
            <CardBody>
              <ProgressBar
                percent={wine?.tank?.percentOfUsage || wine?.tankPercentOfUsage}
                color={'info'}
                title={'Zapełnienie zbiornika'}
              />
            </CardBody>
          ) : null}
          <Table>
            <tbody>
              {wine?.startDate ? (
                <CommonRow
                  label={wine.entity === 'wine' ? 'Data rozpoczęscia' : 'Data początkowa'}
                  value={wine.startDate.toString()}
                />
              ) : null}
              {wine?.entity === 'wine_production' && updateTankInWineProduction && (
                <CommonRow
                  label={'Wybierz zbiornik'}
                  value={
                    <SelectTank
                      value={wine?.tank || {}}
                      name={'tank'}
                      disabled={wine?.liveStatus === EntityLiveStatus.ARCHIVED}
                      onChange={updateTankInWineProduction}
                      error={error}
                      key={wine?.tank?.number}
                    />
                  }
                />
              )}
              <CommonRow label={'Litry aktualnie'} value={wine?.liters} />

              {!isBoardPage && (
                <>
                  <CommonRow
                    label={'Ostatni dodany składnik'}
                    value={wine?.lastIngredient ? wine?.lastIngredient?.name : 'Brak'}
                  />
                  <CommonRow
                    label={'Ostatni proces'}
                    value={wine?.lastEvent?.type ? lastEventName : 'Brak'}
                  />
                  {wine?.harvest ? (
                    <CommonRow
                      label={'Ze zbioru'}
                      value={`${wine?.harvest?.grapevine?.name || wine?.harvest?.varietyName} (${
                        wine?.harvest?.dateOfHarvest
                      })`}
                      path={`/mv/harvest/info/${wine?.harvest?.id}`}
                    />
                  ) : null}
                </>
              )}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter className="text-center bg-secondary">
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={saveEditButton.action}
          >
            {saveEditButton.label}
          </button>
          {toProductionButton &&
          wine.liveStatus !== EntityLiveStatus.ARCHIVED &&
          wine.liters > 0 ? (
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              onClick={toProductionButton.action}
            >
              {toProductionButton.label}
            </button>
          ) : null}
          {archiveButton?.action && (
            <button
              className="btn btn-warning ml-1 btn-sm"
              type="button"
              onClick={archiveButton?.action}
            >
              {archiveButton?.label}
            </button>
          )}
          {error && <FormErrorMessage error={error} messageType={'details'} />}
        </CardFooter>
      </Card>
    </PageWrapper>
  );
};

export default WineInfoBox;
