import { useState } from 'react';
import { useProductionEventContext } from '../context/ProductionEventContext';
import { Service, ServiceError, StatusType } from '../../../../../types/Service';
import log from 'loglevel';
import { ResponseError } from '../../../../error/ResponseError';
import { ProductionEvent } from '../../wine/types/Wine';
import { useHistory, useParams } from 'react-router-dom';
import { ToApiConverter } from '../../../../../services/Converters';
import { useEventHandlerActions } from '../../../hooks/useEventHandlerActions';
import { RouterParams } from '../../../../../types/RouterParams';

export const useProductionEventOnClickService = <T>(
  addEvent: (event: ProductionEvent) => Promise<T>,
  editEvent: (eventId: number, event: ProductionEvent) => Promise<T>,
  fieldName: 'wine' | 'wine_production',
  setResult: (value: Service<T>) => void
) => {
  const {
    productionEvent: event,
    setProductionEventResult,
    updateProductionEvent: update
  } = useProductionEventContext();

  const [loading, setLoading] = useState(false);

  const { productionEventId } = useParams<RouterParams>();

  const [key, setKey] = useState(new Date());

  const history = useHistory();

  const {
    onChange,
    updateSelect: updateTypeSelect,
    updateDateTime: updateDate
  } = useEventHandlerActions(update);

  const saveOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addEvent(ToApiConverter.convertEvent(event))
      .then((response) => {
        update('reset', '');
        setResult({ status: StatusType.loaded, payload: response });
        setKey(new Date());
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((response) => {
        log.debug(response);
        setProductionEventResult(new ResponseError<ProductionEvent>(response) as ServiceError);
      })
      .finally(() => setLoading(false));
  };

  const updateOnSubmit = (e) => {
    e.preventDefault();
    if (productionEventId) {
      setLoading(true);
      editEvent(parseInt(productionEventId), ToApiConverter.convertEvent(event))
        .then(() => history.push(history?.location?.state?.['from'] || `/mv/${fieldName}/all`))
        .catch((response) => {
          log.debug(response);
          setProductionEventResult(new ResponseError<ProductionEvent>(response) as ServiceError);
        })
        .finally(() => setLoading(false));
    }
  };

  return {
    onChange,
    updateTypeSelect,
    onSubmit: { update: updateOnSubmit, save: saveOnSubmit },
    updateDate,
    key,
    loading
  };
};
