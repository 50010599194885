import React from 'react';
import { PaymentInfo } from './PaymentInfo';
import { Subscription, SubscriptionStatus, SubscriptionType } from '../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { Plans } from '../../../plans/data/Plans';
import { useAuthContext } from '../../../platform/AuthContext';

export const PastDuePlanInfo: React.FC<{
  navigateToPlans: () => void;
  price: string;
  title: string;
  inProgressSubscription?: Subscription;
  color: string;
}> = ({ navigateToPlans, price, title, inProgressSubscription, color }) => {
  const { t } = useTranslation();
  const { principal } = useAuthContext();

  const chosenPlan = Plans.find(
    (plan) => plan.type === inProgressSubscription?.type && plan.status !== SubscriptionStatus.TRIAL
  );
  return (
    <div>
      <span>
        <strong className={'text-danger'}>Twój plan stracił ważność.</strong>
        {inProgressSubscription ? (
          <div className={'pt-2'}>
            Po zaksięgowaniu wpłaty twój nowy plan{' '}
            <span className={color}>
              {t(`plans.subscription.type.${inProgressSubscription.type}`)}
            </span>{' '}
            zostanie aktywowany.
          </div>
        ) : (
          <div>
            Aby przywrócić wszystkie funkie danego planu <strong>dokonaj płatności</strong> zgodnie
            z informacjami podanymi poniżej. Możesz również wcześniej{' '}
            <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
              zmienić plan na inny.
            </span>
          </div>
        )}
        {inProgressSubscription?.type !== SubscriptionType.PREMIUM ? (
          <div>
            Aby korzystać z większej ilości funkcji w każdej chwili możesz{' '}
            <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
              zwiększyć plan.
            </span>
          </div>
        ) : null}
        {inProgressSubscription?.type === SubscriptionType.PREMIUM ? (
          <div>
            Możesz jeszcze{' '}
            <span className="btn-link" onClick={navigateToPlans} style={{ cursor: 'pointer' }}>
              zmienić plan.
            </span>
          </div>
        ) : null}
      </span>
      <PaymentInfo
        price={chosenPlan?.amount?.toString() || price}
        title={
          inProgressSubscription ? principal.login + ' - ' + inProgressSubscription.type : title
        }
      />
    </div>
  );
};
