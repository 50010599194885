import React from 'react';
import WineInfoContainer, { WineInfoButtons } from './WineInfoContainer';
import WineInfoPanel from './WineInfoPanel';

const WineInfo = () => {
  return (
    <WineInfoContainer render={(buttons: WineInfoButtons) => <WineInfoPanel buttons={buttons} />} />
  );
};

export default WineInfo;
