import React, { FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import InputElement from '../../../../../../components/common/InputElement';
import { SelectProductionEventType } from './SelectProductionEventTypeType';
import { EventToWineProps } from './withProductionEventToWineServiceHOC';
import { ProductionEvent, ProductionEventForWineType } from '../../../wine/types/Wine';
import { InputDate } from '../../../../../../components/common/form-elements/InputDate';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { RouterParams } from '../../../../../../types/RouterParams';
import { useParams } from 'react-router-dom';
import { useResponsiveContext } from '../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../hooks/useToggleAndGoToRef';

export const ProductionEventFormFields: FC<EventToWineProps> = ({
  event,
  actions: { onChange, updateTypeSelect, updateDate, onSubmit, key },
  error,
  entity
}) => {
  const { t } = useTranslation();
  const { productionEventId } = useParams<RouterParams>();

  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(!!productionEventId, isMobile);

  function getEventStartingDate(event: ProductionEvent) {
    if (!event.startingDate) {
      event.startingDate = new Date();
    }
    return event.startingDate;
  }

  return (
    <Card key={key} innerRef={currentRef} className={isOpen ? '' : 'bg-info-dark'}>
      <CardBody className={'pt-2 pb-2'}>
        <div
          onClick={() => toggleOpen()}
          style={{ cursor: 'pointer', height: '1.5em' }}
          className={'pt-1'}
        >
          <i
            className="float-left"
            data-tool="card-collapse"
            data-toggle="tooltip"
            title=""
            data-original-title="Collapse Card"
          >
            <em className={isOpen ? 'fa fa-minus text-primary' : 'fa fa-plus'} />
          </i>
          <h5 className="m-0 float-right">
            {t(event?.id ? 'common.EDIT' : 'wine.info.ADD_NEW_EVENT')}
          </h5>
        </div>
        {isOpen && (
          <div className={'mt-2'}>
            <SelectProductionEventType
              value={event?.type}
              name={'type'}
              label={t('add_ingredient.SELECT_TYPE')}
              onChange={updateTypeSelect}
              error={error}
              entity={entity}
            />
            {event?.type === ProductionEventForWineType.OTHER && (
              <InputElement
                label={t('event.NAME')}
                name={'name'}
                defaultValue={event?.name}
                onChange={onChange}
                error={error}
                optional
              />
            )}
            <InputDate
              label={t('event.STARTING_DATE')}
              name={'startingDate'}
              defaultValue={getEventStartingDate(event)}
              onChange={updateDate}
              error={error}
              showTimeSelect
            />
            <InputDate
              label={t('event.ENDING_DATE')}
              name={'endingDate'}
              defaultValue={event?.endingDate}
              onChange={updateDate}
              error={error}
              optional
              showTimeSelect
            />
            <InputElement
              label={t('event.INFO')}
              type={'textarea'}
              name={'info'}
              defaultValue={event?.info}
              onChange={onChange}
              error={error}
              maxSize={'1000'}
              optional
            />
            <InputElement
              label={
                event.type === ProductionEventForWineType.PRESS
                  ? t('event.PRESS.WASTE')
                  : t('event.WASTE')
              }
              type={'number'}
              name={'waste'}
              defaultValue={event?.waste}
              onChange={onChange}
              error={error}
              className={'mb-2'}
              optional
            />
            {event.type === ProductionEventForWineType.PRESS ? (
              <small className="text-muted">{t('event.PRESS.MESSAGE')}</small>
            ) : null}
            <FormErrorMessage error={error} messageType={'details'} />
            <div className={'bg-secondary text-center pb-0'}>
              <Button
                color="info"
                className="btn-square"
                onClick={event?.id ? onSubmit?.update : onSubmit?.save}
              >
                {event?.id ? t('common.SAVE') : t('common.ADD')}
              </Button>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
