import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import WineProvider from '../winery/components/wine/context/WineContext';
import { WineryPage } from './components/WinerySummaryPage';
import { VineyardSummaryPage } from './components/VineyardSummaryPage';
import { StartPage } from './components/StartPage';
import { StorageSummaryPage } from './components/StorageSummaryPage';
import { TanksSummaryPage } from './components/TanksSummaryPage';
import { IngredientsSummaryPage } from './components/IngredientsSummaryPage';
import { ReportsPage } from '../reports/components/ReportsPage';
import { WineStorageApp } from '../winery/components/wine_storage';
import { AuthContext } from '../platform/AuthContext';

export const HomeApp = () => {
  const {
    actions: { hasValidPremium }
  } = useContext(AuthContext);
  return (
    <WineProvider>
      <Switch>
        <Route exact path="/mv">
          <Redirect to="/mv/board/start" />
        </Route>
        <Route exact path="/mv/board/start" component={StartPage} />
        <Route exact path="/mv/board/production" component={WineryPage} />
        <Route exact path="/mv/board/summary" component={VineyardSummaryPage} />
        <Route exact path="/mv/board/tanks" component={TanksSummaryPage} />
        <Route exact path="/mv/board/ingredients" component={IngredientsSummaryPage} />
        {!hasValidPremium ? (
          <Redirect to={'/mv/plans'} />
        ) : (
          <Switch>
            <Route exact path="/mv/reports" component={ReportsPage} />
            <Route exact path="/mv/board/storage" component={StorageSummaryPage} />
            <Route path="/mv/wine_storage" component={WineStorageApp} />
          </Switch>
        )}
      </Switch>
    </WineProvider>
  );
};
