import React from 'react';
import { useTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import { WineBoardPresentationData } from '../../../../../types/dto';
import { FilesListItemColor } from '../../../../../types/FileType';
import { WineColor } from '../../../../winery/components/wine/types/WineProduction';
import { useLongTouch } from '../../../../../hooks/useLongTouch';
import { usePushHistory } from '../../../../../hooks/usePushHistory';

interface IProps {
  wine: WineBoardPresentationData;
  isShort?: boolean;
  onClick: () => void;
}

export const SummaryWineRow: React.FC<IProps> = ({ wine, isShort, onClick }) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();
  function getColor(): FilesListItemColor | string {
    if (wine.wineColor === null) return 'green-light';
    if (wine.wineColor === WineColor.WHITE) return '';
    if (wine.wineColor === WineColor.ORANGE) return '';
    if (wine.wineColor === WineColor.RED) return 'pink';
    if (wine.wineColor === WineColor.ROSE) return 'pink';
    return 'gray';
  }

  function getBackgroundColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return 'rgba(255,207,81,0.49)';
    if (wine.wineColor === WineColor.ORANGE) return 'rgba(255,142,0,0.44)';
    if (wine.wineColor === WineColor.RED) return 'rgba(255,1,1,0.53)';
    if (wine.wineColor === WineColor.ROSE) return 'rgba(255,44,44,0.39)';
    return '';
  }

  function getType(wine: WineBoardPresentationData) {
    if (wine?.wineColor === null) {
      return t('wine_info_box.badge.text.wine');
    }
    return t(`wine.COLOR.${wine.wineColor}`);
  }

  function getTaste(wine: WineBoardPresentationData) {
    if (wine?.wineColor === null) {
      return null;
    }
    return wine.wineTaste ? t(`wine.TASTE.${wine.wineTaste}`) : null;
  }

  const barColor = (wine: WineBoardPresentationData) => {
    if (wine.tankPercentOfUsage > 75 && wine.tankPercentOfUsage <= 85) {
      return wine.entity === 'wine' ? 'warning' : 'green';
    } else if (wine.tankPercentOfUsage > 85) {
      return wine.entity === 'wine' ? 'danger' : 'success';
    }
    return 'info';
  };

  function handleNavigateToEdit(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/${wine.entity}/e/${wine.id}`);
  }

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useLongTouch(handleNavigateToEdit);

  return (
    <tr
      className="odd parent"
      style={{ cursor: 'pointer', userSelect: 'none', WebkitUserSelect: 'none' }}
      onClick={onClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onContextMenu={handleNavigateToEdit}
    >
      {!isShort ? (
        <td className="dtr-control sorting_1" tabIndex={0}>
          <div
            className={'badge bg-' + getColor()}
            style={{ backgroundColor: getBackgroundColor() }}
          >
            {getType(wine)}
          </div>
        </td>
      ) : null}
      <td>{wine.name}</td>
      {isShort ? <td>{wine.startDate}</td> : null}
      <td>{wine.endLiters} l</td>
      <td>{wine.liters} l</td>
      <td>
        {wine.tankPercentOfUsage ? (
          <div className="d-flex align-items-center">
            <div className="w-100">
              <Progress
                className="progress-xs m-0"
                value={wine.tankPercentOfUsage}
                color={barColor(wine)}
              />
            </div>
            <div className="wd-xxs text-right">
              <div className="text-bold text-muted">{wine.tankPercentOfUsage || '0'}%</div>
            </div>
          </div>
        ) : null}
      </td>
      <td>{wine.tankNumber}</td>
      {!isShort ? (
        <td className="dtr-control sorting_1" tabIndex={0}>
          <div className={'badge bg-gray-lighter'}>{getTaste(wine)}</div>
        </td>
      ) : null}
    </tr>
  );
};
