import useCrudeApi from '../../../../../lib/useCrudeApi';
import { ProductionEvent, Wine } from '../types/Wine';
import useFetch from '../../../../../lib/useFetch';
import { useParams } from 'react-router-dom';
import { Ingredient } from '../../ingredient/types/Ingredient';
import { RouterParams } from '../../../../../types/RouterParams';

const useWineService = () => {
  const { harvestId, wineId } = useParams<RouterParams>();
  const crudeApi = useCrudeApi<Wine>(`/ajax/wines`);
  const { get, post, put, del, getList } = crudeApi;

  const getListWithPositiveLiters = () => {
    return crudeApi.getAllWithParams(new Map<string, string>([['mode', 'WITH_LITERS']]));
  };

  const { post: toProduction } = useCrudeApi<Wine>(`/ajax/wines/${wineId}/to_production`);
  const { getAll } = useCrudeApi<Wine>(`/ajax/wines${harvestId ? `/harvest/${harvestId}` : ''}`);
  const { getList: getListByHarvest } = useCrudeApi<Wine>(
    `/ajax/wines${harvestId ? `/harvest/${harvestId}` : ''}`
  );
  const { putBody: addIngredient, del: delIngredient } = useFetch<Ingredient>(
    `/ajax/wines/${wineId}/ingredient`
  );
  const { getAllWithParams: getByHarvest } = useCrudeApi<Wine>(`/ajax/wines/harvest/${harvestId}`);
  const { putId: archive } = useCrudeApi<Wine>('/ajax/wines/archive');
  const { putId: revertArchive } = useCrudeApi<Wine>('/ajax/wines/revert-archive');

  const eventWineCrudApi = useCrudeApi<ProductionEvent>(`/ajax/wines/${wineId}/event`);
  const { put: putIngredient } = useCrudeApi<Ingredient>(`/ajax/wines/${wineId}/ingredient`);

  const addEvent = (body: ProductionEvent) => {
    return eventWineCrudApi.putBody(body);
  };

  const editEvent = (eventId: number, body: ProductionEvent) => {
    return eventWineCrudApi.put(eventId, body);
  };

  const removeEvent = (eventId: number) => {
    return eventWineCrudApi.del(eventId);
  };

  return {
    get,
    getList,
    getAll,
    post,
    put,
    del,
    addIngredient,
    delIngredient,
    getByHarvest,
    addEvent,
    editEvent,
    removeEvent,
    putIngredient,
    archive,
    revertArchive,
    toProduction,
    getListWithPositiveLiters,
    getListByHarvest
  };
};

export default useWineService;
