import React from 'react';
import { ServiceError } from '../../../../../../types/Service';
import { SelectType } from '../../../../../../components/common/form-elements/SelectType';
import { WineEntry, WineEntryType } from '../../../wine/types/WineProduction';
import { useWinesReceiver } from '../../hooks/useWinesReceiver';
import { useWineProductionsReceiver } from '../../hooks/useWineProductionsReceiver';

interface Props {
  wineEntry: WineEntry;
  onChange: (name: string, s: Record<string, string>) => void;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  entryType: WineEntryType;
  name: string;
}

export const SelectWineEntry: React.FC<Props> = ({
  wineEntry,
  onChange,
  label,
  optional,
  error,
  disabled,
  entryType,
  name
}) => {
  if (entryType === WineEntryType.WINE) {
    return (
      <SelectType
        onChange={onChange}
        name={'wine'}
        receiver={useWinesReceiver}
        error={error}
        optional={optional}
        label={label}
        value={wineEntry.wine}
        disabled={disabled}
        errorName={name}
      />
    );
  }
  if (entryType === WineEntryType.WINE_PRODUCTION) {
    return (
      <SelectType
        onChange={onChange}
        name={'wineProduction'}
        receiver={useWineProductionsReceiver}
        error={error}
        optional={optional}
        label={label}
        value={wineEntry.wineProduction}
        disabled={disabled}
        errorName={name}
      />
    );
  }
  return <></>;
};
