import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import SelectIngredientType from '../../IngredientForm/SelectIngredientType';
import SelectIngredient from './SelectIngredient';
import InputElement from '../../../../../../../components/common/InputElement';
import { ServiceError } from '../../../../../../../types/Service';
import { Ingredient } from '../../../types/Ingredient';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import { InputDate } from '../../../../../../../components/common/form-elements/InputDate';

interface Props {
  ingredient: Ingredient;
  actions: {
    updateIngredientSelect: (e: ChangeEvent<HTMLInputElement>) => void;
    updateTypeSelect: (name: string, s: Record<string, string>) => void;
    onChange: () => void;
    updateDate: () => void;
  };
  error: ServiceError;
  editing?: boolean;
}

export const AddIngredientFormFields: FC<Props> = ({
  ingredient,
  actions: { updateIngredientSelect, updateTypeSelect, onChange, updateDate },
  error,
  editing
}) => {
  const { t } = useTranslation();

  function getEventStartingDate(ingredient: Ingredient) {
    if (!ingredient.appliedDate) {
      ingredient.appliedDate = new Date();
    }
    return ingredient.appliedDate;
  }

  return (
    <>
      <SelectIngredientType
        value={ingredient.type}
        name={'type'}
        label={t('add_ingredient.SELECT_TYPE')}
        onChange={updateTypeSelect}
        error={error}
        optional
        disabled={editing}
      />
      <SelectIngredient
        value={ingredient.ingredient}
        type={ingredient.type}
        name={'ingredient'}
        label={t('add_ingredient.SELECT_INGREDIENT')}
        onChange={updateIngredientSelect}
        error={error}
        disabled={editing}
      />
      <InputDate
        label={t('add_ingredient.APPLIED_DATE')}
        name={'appliedDate'}
        defaultValue={getEventStartingDate(ingredient)}
        onChange={updateDate}
        error={error}
        showTimeSelect
      />
      <InputElement
        label={t('add_ingredient.AMOUNT')}
        type={'number'}
        name={'amount'}
        onChange={onChange}
        defaultValue={ingredient.amount}
        error={error}
      />
      <InputElement
        label={t('add_ingredient.NOTES')}
        type={'textarea'}
        name={'notes'}
        onChange={onChange}
        defaultValue={ingredient.notes}
        error={error}
        className={'mb-2'}
        optional
      />
      <FormErrorMessage error={error} />
    </>
  );
};
